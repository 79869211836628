export const LANG_DE_NAME = 'de';
export const LANG_DE_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'Schlüssel',
	'A001Country':'Deutsch',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'Email',
	'A005Login':'Melden Sie sich an',
	'A006Password':'Passwort',
	'A007RemPassword':'Angemeldet bleiben',
	'A008Login':'Anmelden',
	'A009Logout':'Abmelden',
	'A010Production':'Produktionsleistung',
	'A011Availability':'Verfügbarkeit',
	'A012Maintenance':'Maschinenwartung',
	'A013RemainingUnits':'verbleibende Einheiten',
	'A014ProductionTime':'Gesamtzeit',
	'A015ProductionTodayGeneral':'Produktion heute',
	'A015ProductionToday':'Produktion heute',
	'A016Units':'Einheiten',
	'A017RemProduction':'Gesamtproduktion',
	'A018LastReset':'letzter Reset',
	'A019Faults':'Unterbrechungen heute',
	'A019FaultsBatch':'Unterbrechungen',
	'A020Count':'Anzahl',
	'A021FaultDuration':'Dauer Störungen heute',
	'A022Minutes':'min',
	'A023BagCounterPerFS':'Tagessackzähler',
	'A023BagCounterPerFSBatch':'Sackzähler',
	'A024FillingStation':'Füllstation',
	'A025FSDifference':'Streuung Sackzähler',
	'A026AtLeast1Caps':'mindestens 1 Großbuchstabe',
	'A026FSworstspout':'und schlechtestem Stutzen',
	'A027Percent':'Prozent',
	'A028RejectionsToday':'Ausschleusung ',
	'A028RejectionsTodayBatch':'Ausschleusung',
	'A029FilterStop':'Filteranlage',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'Kein Produkt',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'nachf. Bandanlage',
	'A034CompAirFault':'Druckluft',
	'A035TypeChange':'Sortenwechsel ',
	'A035TypeChangeBatch':'Sortenwechsel',
	'A036ProductionRate':'Drehzahlbez. Produktion',
	'A037SpeedRelated':'Säcke / Stunde',
	'A038UnitsPerHour':'Einheiten / Stunde',
	'A039GoodWeights':'Gutproduktion',
	'A040ScissorHose':'Scherenschlauch',
	'A041ConnectingHose':'Verbindungsschlauch',
	'A042ConeGasket':'Kegeldichtung',
	'A043AerationPlates':'Belüftungsplatten',
	'A043ScissorHose':'Scherenschlauch',
	'A044ConnectingHose':'Verbindungsschlauch',
	'A044RegulationValve':'Druckregelventil Förderluft',
	'A045ConeFeeding':'Kegel Packereinlauf',
	'A045ConeGasket':'Kegeldichtung',
	'A046AerationPlates':'Belüftungsplatten',
	'A046BagTransBelt':'Rippenband Sacktransport',
	'A047BagTransBearing':'Spannringlager Sacktransport',
	'A047RegulationValve':'Druckregelventil',
	'A048ConeFeeding':'Kegel Packereinlauf',
	'A048SuctionCup':'Sauger Radimat',
	'A049BagTransBelt':'Sacktransportriemen',
	'A049BagTransCyl':'Zylinder Sacktransport',
	'A050BagTransBearing':'Spannlager',
	'A050SucRailCyl':'Zylinder Saugerleiste',
	'A051SuctionCup':'Sauger Radimat',
	'A051VacuumPump':'Vakuumpumpe /-erzeuger',
	'A052BagTransCyl':'Zylinder Sacktransport',
	'A052BagTransCylinde':'Zylinder Sacktransport',
	'A052CableHightAdj':'Kabel Sattelhöhenverstellung',
	'A053HullMinSensor':'Hülle MIN-Melder',
	'A053SucRailCyl':'Zylinder Saugerleiste',
	'A053SucRailCylinder':'Zylinder Saugerleiste',
	'A054Last48Hours':'letzten 48 Stunden',
	'A054VacuumPump':'Vakuumpumpe',
	'A055CableHightAdj':'Trockner Druckluft',
	'A055Last14Days':'letzten 14 Tage',
	'A056HullMinSensor':'Drehkolbengebläse',
	'A056off':'aus',
	'A057InOperation':'im Betrieb',
	'A058Faulty':'gestört',
	'A059InMaintenance':'in Wartung',
	'A060HourlyValues':'Stundenwerte für',
	'A061DischargesTotal':'Abwürfe gesamt',
	'A062CreationDate':'Erstellungsdatum',
	'A062Period':'Zeitraum',
	'A063To':'bis',
	'A064BatchValue':'Sortenwerte',
	'A064DailyValue':'Tageswerte',
	'A064DailyValues':'Tageswerte für',
	'A065supply':'Zufuhr',
	'A066Transport':'Abtransport',
	'A067Feeding':'Produktzufuhr',
	'A068PackagesMissing':'Gebinde fehlt',
	'A069NoHistoricalData':'Keine historischen Daten für den Zeitraum zwischen ',
	'A070And':'und',
	'A071Available':'vorhanden',
	'A072ReallyReset':' Wirklich zurücksetzen?',
	'A073Details':'Details',
	'A074General':'Allgemein',
	'A075Machines':'Maschinen',
	'A076Dashborad':'Dashboard',
	'A077Administration':'Verwaltung',
	'A078UserRoles':'Benutzer & Rollen',
	'A079Companies':'Firmen',
	'A080Machines':'Maschinen (dev)',
	'A081Date':'Datum',
	'A082Transport':'Abtransport',
	'A083Containermissing':'Gebinde fehlt',
	'A084Availability':'Verfügbarkeit',
	'A085TypeChanges':'Sortenwechsel heute',
	'A086EmptyRejects':'Ausschuss leere Säcke heute',
	'A086EmptyRejectsBatch':'Ausschuss leere Säcke',
	'A087FullRejects':'Ausschuss volle Säcke heute',
	'A087FullRejectsBatch':'Ausschuss volle Säcke',
	'A088OpRelease':'Betriebsfreigabe',
	'A089ProdFeedStop':'Produktzufuhr STOP',
	'A090AuxDrive':'Hilfsantrieb',
	'A091Consumables':'Verbrauchsmaterial',
	'A092DischLineStop':'Austragslinie STOP',
	'A093TypeChange':'Sortenwechsel',
	'A094DailyProduction':'Produktion heute',
	'A094DailyProductionBatch':'Produktion',
	'A095Energy':'Energie',
	'A096EffectivePower':'Wirkleistung',
	'A097ApparentPower':'Scheinleistung',
	'A098CompAirFlow':'Druckluftdurchsatz',
	'A099ResetFailed':'Metrik konnte nicht zurückgesetzt werden.',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'Wartungshilfen hochladen',
	'A1001UploadMaintenanceAidSuccess':'Wartungshilfen wurden erfolgreich hochgeladen.',
	'A1002UploadMaintenanceAidFailure':'Fehler beim Hochladen der Wartungshilfen.',
	'A1005MachineServiceRequest':'Serviceanfrage',
	'A1006RequestService':'Serviceanfrage',
	'A1007ServiceContract':'Servicevertrag',
	'A1008ServiceRequestSuccesful':'Serviceanfrage erfolgreich bearbeitet.',
	'A1009ServiceRequestFailure':'Fehler in der Serviceanfrage',
	'A100AcessDenied':'Zugriff verweigert. ',
	'A100AlertmailSubject':'QSI Alert: MACHINE_NAME',
	'A1010LogBook':'Logbuch',
	'A1011CreateLogBook':'Logbucheintrag erstellen',
	'A1012EditLogBook':'Logbucheintrag bearbeiten',
	'A1013LogBookItemType':'Logbuchelementtyp',
	'A1014LogBookItem':'Logbucheintrag',
	'A1015LogBookItemSaved':'Logbucheintrag gespeichert',
	'A1016ErrorInSavingLogBookItem':'Fehler beim Speichern des Logbucheintrags',
	'A1017ErrorInRetrievingLogBookItem':'Fehler beim Abrufen des Logbuchs.',
	'A1018ErrorInDeletingLogBookItem':'Fehler beim Löschen des Logbucheintrags',
	'A1019LogBookItemDeleted':'Logbucheintrag wurde gelöscht',
	'A101MailSalutation':'Hallo USER_NAME,',
	'A101ResetSuccessful':'Metrik wurde zurückgesetzt.',
	'A1020LogBookItemIsDone':'Erledigt',
	'A1021LogBookItemType':'Eintragstyp',
	'A1022ServiceEmail':'Service-E-Mail',
	'A1023CannotMakeServieRequest':'Sie können keinen Service anfordern, da dieser Maschine keine Service-E-Mail-Adresse zugewiesen wurde.',
	'A1024MaxUsers':'Maximale Benutzer',
	'A1024MaxUsersReached':'Das maximale Benutzerlimit des Unternehmens wurde erreicht.',
	'A1025AtLeast8Characters':'mindestens 8 Zeichen',
	'A1027AtLeast1Lowercase':'mindestens 1 Kleinbuchstabe',
	'A1028AtLeast1Number':'mindestens 1 Nummer (0-9)',
	'A1029AtLeast1SpecialChar':'mindestens 1 Sonderzeichen (!,. [])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71  Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Telefon: +33 389 6337 53',
	'A102CompanyPhoneNumberFeige':'Telefon: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Telefon: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Telefon: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Telefon: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Telefon: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Telefon: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'Produktwechsel heute',
	'A102TypeChangesBatch':'Produktwechsel',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'Beschreibung',
	'A103EndOfBatch':'Ende der Charge',
	'A103ResetmailSubject':'Reset : MACHINE_NAME',
	'A104FullPalletCount':'Vollpaletten',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'Verbindungsfehler : MACHINE_NAME',
	'A105LoadEmptyPallets':'Laden leerer Paletten',
	'A106Fillings':'Füllungen',
	'A106PLCMailSubject':'PLC Alert : MACHINE_NAME',
	'A107FillingsPerHour':'Füllungen / Stunde',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'Verfügbarkeit',
	'A108AvailabilityMailSubject':'Availability Alert : MACHINE_NAME',
	'A109FillingsRemain':'Noch übrige Füllungen',
	'A110StartOfOrder':'Abfüllauftrag Startzeit',
	'A111ProductID':'Produkt ID',
	'A112TargetWeight':'Sollgewicht',
	'A113Kg':'Kilogramm',
	'A114CurrentOrder':'Aktueller Auftrag',
	'A115FillingCount':'Aktueller Auftrag',
	'A116CycleTime':'Zykluszeiten Maschine',
	'A117Total':'Gesamt',
	'A118CentreStation':'Zentrierstation',
	'A119N2Station':'N2 Station',
	'A120FillingStation':'Füllstation',
	'A121SealingStation':'Verschließstation',
	'A122Seconds':'Sekunden',
	'A123FaultLength':'Störungen Maschine',
	'A124Minutes':'Minuten',
	'A124Minute':'Minuten',
	'A125ExtFaultLength':'Externe Störungen',
	'A126FillingCount':'Gesamtzahl der Füllungen',
	'A127WithinTolerance':'Füllungen innerhalb Toleranz',
	'A128OutsideTolerance':'Füllungen außerhalb Toleranz',
	'A129BagManufacture':'Gutproduktion',
	'A130BagsPerHour':'Säcke / Stunde',
	'A131BagQuality':'Beutelqualität',
	'A132CorrPlacedbags':'Richtig platzierte Säcke',
	'A133TwoAttempts':'2. Versuch zu öffnen',
	'A134ThreeAttempts':'3. Versuch zu öffnen',
	'A135DiscardeBags':'Verworfene Säcke',
	'A136Faults':'Unterbrechungen',
	'A137CompAir':'Druckluft',
	'A138ProductFeed':'Produkt-Feed (Waage)',
	'A139EmptyBagMag':'Leersackmagazin',
	'A140Separation':'Trennung',
	'A141Vacuum':'Vakuum',
	'A142FillingStation':'Füllstation',
	'A143FullBagTransport':'Full Bag Transport',
	'A144BagSealing':'Sackverschließung',
	'A145Disrupt':'Störungen',
	'A145AccessDoor':'Zugangstür',
	'A145DSafetyLightBarrier':'Sicherheitslichtschranke',
	'A145UpstreamSupply':'Upstream-Versorgung',
	'A145EmergencyStop':'Not-Halt',
	'A146BunChangeAbs':'Anzahl der Bündelwechsel Gesamt',
	'A147BunChangeToday':'Anzahl der Bundlewechsel heute',
	'A147BunChangeTodayBatch':'Anzahl der Bundlewechsel',
	'A148BagsPerBundle':'Anzahl der Taschen pro Bundle',
	'A149TypeChanges':'Sortenwechsel heute',
	'A149TypeChangesBatch':'Sortenwechsel',
	'A150FillingTime':'Füllzeit',
	'A151DosingUnit1':'Produktzuführung',
	'A152DosingUnit2':'Produktzuführung 2',
	'A153AerInletBox1':'Belüftung Einlaufkasten',
	'A154AerInletBox2':'Belüftung Einlaufkasten 2',
	'A154DosingUnit1':'Produktzuführung',
	'A155DosingUnit2':'Produktzuführung 2',
	'A155RotaryDrive':'Drehantrieb',
	'A156AerInletBox1':'Belüftung Einlaufkasten',
	'A156DischargeBelt':'Austrageband',
	'A157AerInletBox2':'Belüftung Einlaufkasten 2',
	'A157AlignmentBelt':'Sackrichtband',
	'A158BagCleaning':'Sackreinigung',
	'A158RotaryDrive':'Drehantrieb',
	'A159AccelerationBelt':'Beschleunigungsband',
	'A159DischargeBelt':'Austrageband',
	'A160AlignmentBelt':'Sackrichtband',
	'A160CheckBelt':'Kontrollwaagenband',
	'A161BagCleaning':'Sackreinigung',
	'A161FlatBelt':'Flachgurtförderer',
	'A162AccelerationBelt':'Beschleunigungsband',
	'A162RejectionBelt':'Bandantrieb Ausschl.',
	'A163CheckBelt':'Kontrollwaagenband',
	'A163RejectionDrive':'Ausschl. Hubantrieb',
	'A164FlatBelt':'Flachgurtförderer',
	'A164RotaryCutter':'Sackzerkleinerer',
	'A165RejectionBelt':'Bandantrieb Ausschl.',
	'A165ScreenDrum':'Siebtrommel',
	'A166RejectionDrive':'Ausschl. Hubantrieb',
	'A166Welding':'Schweißvorrichtung',
	'A167Cutter':'Messer',
	'A167RotaryCutter':'Sackzerkleinerer',
	'A168ScreenDrum':'Siebtrommel',
	'A168SuctionCup':'Sauger',
	'A169Brakes':'Bremsen',
	'A170VacuumPump':'Vakuumpumpe',
	'A171Filter':'Filter Absaugung',
	'A172BagDisConveyor':'Sackverteilungsband',
	'A173BagTurnConveyor':'Sackwendeförderer',
	'A174BagTurnCross':'Sackwendekreuz',
	'A175BagDisConveyor':'Sackverteilungsband',
	'A175BagTurnClamp':'Sackwendeklammer',
	'A176BagTurnConveyor':'Sackwendeförderer',
	'A176GroupingBelt':'Sammelband',
	'A177BagTurnCross':'Sackwendekreuz',
	'A177RowPusher':'Abschieber',
	'A178DoubleStripPlate':'Doppeltes Packblech',
	'A179ConformingPlate':'Leiste',
	'A179GroupingBelt':'Sammelband',
	'A180GantrySafety':'Sicherheit Gerüst',
	'A180RowPusher':'Abschieber',
	'A181DoubleStripPlate':'Doppeltes Packblech',
	'A181Gantry':'Gerüst ',
	'A182ConformingPlate':'Leiste',
	'A182PalletAlignment':'Leerpalettenausrichter',
	'A183GantrySafety':'Sicherheit Gerüst',
	'A183PalletRoller1':'Rollenbahn',
	'A184Gantry':'Gerüst ',
	'A184PalletDispenser':'Palettenstapler',
	'A185PalletAlignment':'Leerpalettenausrichter',
	'A185PalletRoller2':'Rollenbahn 2',
	'A186PalletRoller1':'Rollenbahn 1',
	'A186PalletRoller3':'Rollenbahn 3',
	'A187CyclesChain':'Taktzahl Kette',
	'A187PalletDispenser':'Palettenstapler',
	'A188BundlePusher':'Zylinder Bündelschieber',
	'A188PalletRoller2':'Rollenbahn',
	'A189BundlePartition':'Zylinder Bündelfachteiler',
	'A189PalletRoller3':'Rollenbahn',
	'A190BagLifter':'Zylinder Sackanheber',
	'A190CyclesChain':'Taktzahl Kette',
	'A191BundlePusher':'Zylinder Bündelschieber',
	'A191PressRolls':'Zylinder Andrückrollen',
	'A192BundlePartition':'Zylinder Bündelfachteiler',
	'A192OpeningStation':'Zylinder Öffnungsstation',
	'A193BagApplicator':'Zylinder Sackanleger',
	'A193BagLifter':'Zylinder Sackanheber',
	'A194BagSupport':'Zylinder Sackunterstützung',
	'A194PressRolls':'Zylinder Andrückrollen',
	'A195OpeningStation':'Zylinder Öffnungsstation',
	'A195Pusher':'Zylinder Abschieber',
	'A196BagApplicator':'Zylinder Sackanleger',
	'A196StretchPliers':'Zylinder Spreizzange',
	'A197BagSupport':'Zylinder Sackunterstützung',
	'A197FillingStation':'Zylinder Füllstation',
	'A198FilledBagGripper':'Zylinder Vollsackgreifer',
	'A198Pusher':'Zylinder Abschieber',
	'A199StretchPliers':'Zylinder Spreizzange',
	'A199SucBagSeperation':'Sauger Sackvereinzelung',
	'A200FillingStation':'Zylinder Füllstation',
	'A200SucOpenStation':'Vakuumsauger Öffnungsstation',
	'A201FilledBagGripper':'Zylinder Vollsackgreifer',
	'A201Remaininghours':'verbleibende Stunden',
	'A202PriceTotal':'Stück insgesamt',
	'A202SucBagSeperation':'Sauger Sackvereinzelung',
	'A203RemainingNumberFillings':'Verbleibende Füllungen',
	'A203SucOpenStation':'Vakuumsauger Öffnungsstation',
	'A204SafetyDoor':'Sicherheitstür',
	'A205SafetyLightBarrier':'Sicherheitslichtvorhang',
	'A206BagTransport':'Sacktransport',
	'A207EmptyPalletTrans':'Leerpalettentransport',
	'A208Palletizer':'Palettierer',
	'A209FullPalletTrans':'Vollpalettentransport',
	'A210NumberTodayPiece':'Anzahl heute / Stück',
	'A211Programme':'Programm',
	'A212Efficiency':'Stutzeneffizienz',
	'A213Loginsuccessful':'Login erfolgreich',
	'A214CurrentPerformance':'Aktuelle Leistung',
	'A215CyclesToday':'Gesamtzyklen heute',
	'A215CyclesTodayBatch':'Gesamtzyklen',
	'A216RejectsToday':'Ausschleusungen heute',
	'A216RejectsTodayBatch':'Ausschleusungen',
	'A217MetalDetectorToday':'Metalldetektor heute',
	'A217MetalDetectorTodayBatch':'Metalldetektor',
	'A218BrokenBagsToday':'Erkannte Bruchsäcke heute',
	'A218BrokenBagsTodayBatch':'Erkannte Bruchsäcke',
	'A219FaultPerShift':'aktuelle Schicht',
	'A220FaultBagManufacture':'Sackherstellung',
	'A221FaultBagFilling':'Sackbefüllung',
	'A222FaultBagTransport':'Sacktransport',
	'A223FaultSealing':'Verschließung',
	'A224FaultWeigher':'Füllstation',
	'A225FaultPeriphery':'Andere Peripherie',
	'A226Shift':'Schicht',
	'A227Container':'Container',
	'A228Hours':'Stunden',
	'A229NoPackage':'keine Packmittel',
	'A230NoProduct':'kein Produkt',
	'A231NoOutlet':'kein Austrag',
	'A232GoodPerformance':'Produktion',
	'A233GoodWeights':'Produktion Gutsäcke',
	'A234NotPlacedBags':'Nicht aufgesteckte Säcke',
	'A234NotPlacedBagsAutomatic':'Nicht aufgesteckte Säcke Autom.',
	'A235CollectiveFaultsToday':'Sammelstörungen ',
	'A235CollectiveFaultsTodayBatch':'Sammelstörungen',
	'A236Applicator':'Aufstecker',
	'A237NetWeigher':'Nettowaage',
	'A238IncorrectWeight':'Fehlgewicht',
	'A239Hours':'Stunden',
	'A240QrCode':'QR Code',
	'A241OpenBags':'Offene Säcke',
	'A242FaultCheckWeigher':'Kontrollwaage',
	'A243DashboardAllMachines':'Dashboard / Alle Maschinen',
	'A244DashboardState':'Status',
	'A245DashboardMachineStateRunning':'Eingeschaltet',
	'A246DashboardMachineStateOffline':'Ausgeschaltet',
	'A247DashboardMachineStateMaintenance':'Wartung erforderlich',
	'A248DashboardMachineStateError':'Störung',
	'A249SortChangeToday':'Sortenwechsel heute',
	'A249SortChangeTodayBatch':'Sortenwechsel',
	'A250EmptyBagMagazineToday':'Neubefüllung Magazin',
	'A250EmptyBagMagazineTodayBatch':'Neubefüllung Magazin',
	'A251FaultCheckWeigher':'Störungen heute',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'N/A',
	'A254CyclePumpFS1':'Pumpentakt FS 1',
	'A255CyclePumpFS2':'Pumpentakt FS 2',
	'A256CyclePumpFS3':'Pumpentakt FS 3',
	'A257ConHoseFS1':'Verbindungsschläuche FS1',
	'A258ConHoseFS2':'Verbindungsschläuche FS2',
	'A259ConHoseFS3':'Verbindungsschläuche FS3',
	'A260InfSleeveFS1':'Blähmanschette FS 1',
	'A261InfSleeveFS2':'Blähmanschette FS 2',
	'A262InfSleeveFS3':'Blähmanschette FS 3',
	'A263BundlePusher':'Bündelschieber',
	'A264VacuumUnit':'Vakuumeinheit',
	'A265TravellingDrive':'Verfahrwagen',
	'A266CenteringUnit':'Zentrierung',
	'A267EmptyBagMagazine':'Leersackmagazin',
	'A268BeltPlant':'Bandanlage',
	'A269IvtTurbine':'Füllturbine',
	'A270IvtTripSaddle':'Sattel',
	'A271IvtCoarseFeed':'Dosierung Grobstrom',
	'A272IvtBagHolder':'Sackhalter',
	'A273IvtFillBoxAir':'Fülltopfbelüftung im Deckel',
	'A274IvtFillChanAir':'Füllkanalbelüftung',
	'A275IvtInflSleeve':'Blähmanschette',
	'A276IvtInletBoxAir':'Belüftung Einlaufkasten',
	'A277IvtFillTubeBlow':'Füllrohr ausblasen',
	'A278IvtOutlChanAir':'Ausblaskanal',
	'A279IvtPusher':'Abschieber',
	'A280IvtSaddleHeight':'Sattelhöhenverstellung',
	'A281IvtCloseAnvil':'Amboss schließen',
	'A282IvtBagSeal':'Sackverschließung',
	'A283IvtFineFeedAdj':'Feinstromverstellung',
	'A284Interrupts':'Unterbrechungen ',
	'A284InterruptsGeneralPage':'Unterbrechungen ',
	'A284InterruptsBatch':'Unterbrechungen',
	'A285BagCounterShift':'Sackzähler pro Schicht',
	'A286WrongBags':'Falsch aufgesteckte Säcke',
	'A287ReelChanges':'Rollenwechsel',
	'A287ReelChangesBatch':'Rollenwechsel',
	'A288VibrationDuration':'Verlängerte Rütteldauer',
	'A289Total':'Gesamt',
	'A290HopperMin':'durch Silo Minimum',
	'A291ManualActivation':'Manuelle Einschaltung',
	'A292FillingRelease':'Füllungsfreigabe',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Palettentransport',
	'A295SafetyCircuit':'Sicherheitskreis',
	'A296FltCompAir':'Fehler Druckluft heute',
	'A296FltCompAirBatch':'Fehler Druckluft',
	'A297StrokesPump':'Hübe Pulverpumpe heute',
	'A297StrokesPumpBatch':'Hübe Pulverpumpe',
	'A298MaxStrokeDur':'maximale Hubdauer heute',
	'A298MaxStrokeDurBatch':'maximale Hubdauer',
	'A299AvgStrokes':'Durchschn. Hübe pro Sack heute',
	'A299AvgStrokesBatch':'Durchschn. Hübe pro Sack',
	'A300InternalPressure':'Innendruck',
	'A301Max':'max',
	'A301MessageAdministration':'Nachrichtenverwaltung',
	'A302ErrorNumber':'Fehlernummer',
	'A302Millibar':'mbar',
	'A303ActivateAlertMail':'Aktivieren Sie die Benachrichtigungs-Email.',
	'A303SetValueMax':'Maximalwert setzen',
	'A304AlertText':'Warnungstext',
	'A304SetValueMin':'Minimalwert setzen',
	'A305ActivateResolvedMail':'Aufgelöste E-Mail aktivieren.',
	'A305SetValueChanged':'Ihre Änderungen wurden gespeichert.',
	'A305SetValueChangedRefreshWindow':'Ihre Änderungen wurden gespeichert. Die Änderungen werden nach der Aktualisierung des Browserfensters sichtbar.',
	'A306KW':'kW',
	'A306Resolved Text':'Aufgelöster Text',
	'A306ResolvedText':'Aufgelöster Text',
	'A307AddMessage':'Nachricht hinzufügen',
	'A307KVA':'kVA',
	'A308EditMessage':'Nachricht bearbeiten',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'Druckluftvolumen',
	'A312ActiveEnergy':'Wirkenergie',
	'A313ReactivePower':'Blindleistung',
	'A314ChartConfiguration':'Konfiguration der Kachel',
	'A315SetDateValueMax':'Maximaldatum setzen',
	'A316SetDateValueMin':'Minimaldatum setzen',
	'A317InvalidData':'Ungültige Daten',
	'A318DeleteUser':'Wollen Sie den Benutzer wirklich löschen?',
	'A319EnterAllFields':'Bitte alle Eingabefelder ausfüllen!',
	'A320EnterValidEmail':'Bitte gültige E-Mail-Adresse eingeben!',
	'A321DeleteCompany':'Wollen Sie die Firma wirklich löschen?',
	'A322CouldNotDeleteUser':'Der Admin-Benutzer konnte nicht gelöscht werden! Bitte zuerst in Standard-Benutzer umwandeln.',
	'A323LoginSuccess':'Anmeldung erfolgreich!',
	'A324Selected':'ausgewählt',
	'A325Total':'gesamt',
	'A326Reset':'Zurücksetzen',
	'A327Reporting':'Berichterstattung',
	'A328ReportingActive':'aktiviert',
	'A329ReportingActiveHelpPrefix':'Wenn aktiviert, dann werden regelmäßig Reports an',
	'A330ReportingActiveHelpSuffix':'gesendet.',
	'A331ReportingFrequency':'Frequenz',
	'A332ReportingFrequencyDaily':'täglich',
	'A333ReportingFrequencyWeekly':'wöchentlich',
	'A334ReportingFrequencyMonthly':'monatlich',
	'A335ReportingDynContent':'Dynamische Inhalte',
	'A336ReportingDynContIncludeElapsedMI':'abgelaufene Wartungsintervalle',
	'A337ReportingLanguage':'Sprache',
	'A338ReportingProdDayEnd':'Ende eines Produktionstages',
	'A339ReportingFirstReport':'Datum des ersten Reports',
	'A340CardCtxMenuReport':'in den Report aufnehmen',
	'A341CardCtxMenuHistoReport':'als Histogramm in den Report aufnehmen',
	'A342CardCtxMenuReset':'Zählerstand zurücksetzen',
	'A343CardCtxMenuSettings':'Einstellungen',
	'A344CardLabelReport':'Report',
	'A345CardLabelHistoReport':'Histogramm-Report',
	'A346CardLabelMaintenReport':'Wartungs-Report',
	'A347ReportSettings':'Einstellungen',
	'A348Alerting':'Alarmmeldungen',
	'A349AlertingSettings':'Einstellungen',
	'A350AlertingActive':'aktiviert',
	'A351AlertingActiveHelpPrefix':'Wenn aktiviert, dann werden Benachrichtigungen der unten ausgewählten Arten an',
	'A352AlertingActiveHelpSuffix':'gesendet.',
	'A353AlertingTypes':'Benachrichtigen bei',
	'A354AlertTypeMainten':'Wartungsintervall abgelaufen zu:',
	'A355AlertTypeAvail':'Verfügbarkeit',
	'A356AlertTypePLC':'SPS-Trigger',
	'A357AlertTypeConError':'Verbindungsfehler',
	'A358DeleteAdminUser':'Der Admin-Benutzer konnte nicht gelöscht werden.',
	'A359MaintAids':'Wartungshilfen',
	'A360MaintInt':'Wartungsintervalle',
	'A361ChooseMaschine':'Maschine Wählen',
	'A362Video':'Video',
	'A363InstructionManual':'Bedienungsanleitung',
	'A364ExplodedviewDrawing':'Explosionszeichnung',
	'A364SparePartCatalog':'Ersatzteilkatalog',
	'A365OpenVideoHelp':'Öffnen Sie die Video-Hilfe',
	'A366OpenInstructionManual':'Öffnen Sie die Bedienungsanleitung',
	'A367OpenExploadedView':'Öffnen Sie die Explosionszeichnung',
	'A368ResetMaschineData':'Maschinendaten zurücksetzen',
	'A369BotWeld':'Bodennahtschweißung ',
	'A370BotWeldTef':'Teflon Bodennahtschweißung ',
	'A371TopWeld':'Kopfnahtschweißung ',
	'A372TopWeldTef':'Teflon Kopfnahtschweißung ',
	'A373LeftWeld':'Eckenschweißung links ',
	'A374LefWeldTef':'Teflon Eckenschweißung links',
	'A375RigWeld':'Eckenschweißung rechts ',
	'A376RigWeldTef':'Teflon Eckenschweißung rechts ',
	'A377CutKnife':'Schneidwerkzeug ',
	'A378ReallyClearReport':'Diese Aktion enfternt alle Elemente aus dem Report und kann nicht rückgängig gemacht werden. Möchten Sie wirklich alle Elemente aus dem Report entfernen?',
	'A379ProdCurrShift':'Produktion aktuelle Schicht',
	'A380ReallyDeleteItemReport':'Diese Aktion entfernt das Element aus dem Report und kann nicht Rückgängig gemacht werden. Möchten Sie wirklich das Element aus dem Report entfernen?',
	'A380TypeCurrent':'Aktuelle Sorte',
	'A381SampleBags':'Ausgeschl. Probesäcke',
	'A382OpenBags':'Ausgeschl. offene Säcke',
	'A383IncorrectBags':'Ausgeschl. fehlgew. Säcke',
	'A384NotPlacedBagsMan':'Nicht aufgest. Säcke Hand',
	'A385Spout1':'Wartung Füllstutzen 1',
	'A386LastUpdate':'letzte Aktualisierung:',
	'A386Spout2':'Wartung Füllstutzen 2',
	'A387Spout3':'Wartung Füllstutzen 3',
	'A388Spout4':'Wartung Füllstutzen 4',
	'A389LastUpdate':'letzte Aktualisierung',
	'A390FilledBagTrans':'Vollsacktransport',
	'A391ConveyerSystem':'Austragebänder',
	'A392BeltBundleTrans':'Riementrieb Bündeltransport',
	'A393BagHolderBuffer':'Sackhalter Gummipuffer',
	'A394SlidePlates':'Schieberplatten',
	'A395ImpellerShaft':'Füllwellenlagerung',
	'A396ShaftAssembly':'Untere Lagerung',
	'A397ThreePosCylinder':'Dreistellungszylinder',
	'A398DateFormatNoSeconds':'DD.MM.YYYY HH:mm',
	'A399DateFormat':'DD.MM.YYYY HH:mm:ss',
	'A399DateFormatAngularPipe':'dd.MM.yyyy HH:mm:ss',
	'A400ConveyorSystem':'Bandanlage',
	'A400History':'Verlauf',
	'A401ReportHistory':'Bericht Verlauf',
	'A402ReportId':'Bericht ID',
	'A403ReportGeneratedTime':'Generierte Zeit melden',
	'A404DosingFeeder':'Dosierorgan',
	'A404ReportGeneratedBy':'Bericht erstellt von',
	'A405DownloadReport':'Bericht herunterladen',
	'A406TriggeredHistoryReport':'Die Berichtserstellung wurde gestartet!',
	'A407ViewReport':'Zeige Bericht',
	'A408BagHolder':'Sackhalter',
	'A408BagHolder ':'Sackhalter',
	'A414BundleTransport':'Bündeltransport',
	'A415HoistingDevice':'Hubwerk',
	'A416PlacerArm':'Aufsteckarm',
	'A417CrossProcess':'Querverfahren',
	'A418OpeningStation':'Öffnungsstation',
	'A421Claw':'Klaue',
	'A422WeightData':'Mittelwert',
	'A423StdDevData':'Standardabweichung',
	'A430kg':'kg',
	'A431lb':'lb',
	'A432t':'t',
	'A433klb':'klb',
	'A434g':'g',
	'A435BagManufacture':'Leersackherstellung',
	'A436Aux_Pump':'Vakuumpumpe',
	'A437TopWelding':'Kopfnahtschweißung',
	'A438PunchingDeviceWelding':'Schweißung Ausstanzung',
	'A439Aux_Vibrator1':'Rüttler 1',
	'A440Aux_Vibrator2':'Rüttler 2',
	'A441Aux_Vibrator3':'Rüttler 3',
	'A442Truck_Loading':'LKW Beladung',
	'A443Truck_Available':'Kein LKW verfügbar heute',
	'A444FS1':'Füllstation 1',
	'A445FS2':'Füllstation 2',
	'A446FS3':'Füllstation 3',
	'A447FS4':'Füllstation 4',
	'A448FS5':'Füllstation 5',
	'A449FS6':'Füllstation 6',
	'A450FS7':'Füllstation 7',
	'A451FS8':'Füllstation 8',
	'A452FS9':'Füllstation 9',
	'A453FS10':'Füllstation 10',
	'A454FS11':'Füllstation 11',
	'A455FS12':'Füllstation 12',
	'A456FS13':'Füllstation 13',
	'A457FS14':'Füllstation 14',
	'A458FS15':'Füllstation 15',
	'A459FS16':'Füllstation 16',
	'A460OeeValue':'OEE Kennzahl',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'Verfügbarkeitfaktor',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'Leistungsfaktor',
	'A463DowntimePlaned':'Geplante Stillstände',
	'A464DowntimeMachine':'Maschinenstillstände',
	'A465DowntimeOther':'Weitere Stillstände',
	'A466OperatingFactor':'Betriebsfaktor',
	'A467Throughput':'Durchsatzfaktor',
	'A468OeeRejects':'Ausschuss Heute',
	'A469OeeQuality':'Qualitätsfaktor',
	'A470OeeDowntime':'Stillstände',
	'A471RejectsTotal':'Ausschleusung gesamt',
	'A472RejectsIncorrect':'Ausschleusung Fehlgewicht',
	'A473RejectsOpen':'Ausschleusung offener Sack',
	'A474RejectsSample':'Ausschleusung Probesack',
	'A475RejectsMetal':'Ausschleusung Metall',
	'A476ConfigTable':'Konfiguration ',
	'A477OEERowActive':'Aktiv',
	'A478OEEProduction':'Produktion',
	'A479OEEBreak':'Pause',
	'A480OEEMaint':'Wartung',
	'A481OEETypeChg':'Sortenwechsel',
	'A482OEEOthers':'Weitere Ausfallzeiten',
	'A483OEEFrequ':'Frequenz',
	'A484OEEUnique':'Einmalig',
	'A485OEEDaily':'Täglich',
	'A486OEEWeekly':'Wöchentlich',
	'A487Days':'Tage',
	'A488Monday':'Montag',
	'A489Tuesday':'Dienstag',
	'A490Wednesday':'Mittwoch',
	'A491Thursday':'Donnerstag',
	'A492Friday':'Freitag',
	'A494Saturday':'Samstag',
	'A498Comment':'Kommentar',
	'A495Sunday':'Sonntag',
	'A496From':'Von',
	'A497To':'Bis',
	'A499TabMachineMessages':'Meldungen Maschine',
	'A500MMLive':'Meldungen Live',
	'A500VbeltDrive':'Antriebsriemen Drehantrieb',
	'A501MMStatistic':'Meldungen Statistik',
	'A501VbeltTurbine':'Keilriemen Turbine',
	'A502Impeller':'Schaufelrad',
	'A502MMLive':'Meldungen Maschine Live',
	'A503FillingTube':'Füllrohr',
	'A503MMStatistic':'Meldungen Maschine Statistik',
	'A504BagGuide':'Sackleitblech',
	'A504Type':'Art',
	'A505Control':'Steuerung',
	'A505Saddle':'Gittersattel',
	'A506BoltBagChair':'Bolzen Sackstuhl',
	'A506Number':'Nummer',
	'A507BushBagChair':'Buchse Sackstuhl',
	'A507Message':'Meldung',
	'A508Amount':'Anzahl',
	'A508BearingPusher':'Lagerung Abschieber',
	'A509Duration':'Dauer in Minuten',
	'A509RearSlidePlate':'hintere Gleitplatte',
	'A510SlideValve':'Schieberplatte',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'vordere Gleitplatte',
	'A511Tons':'Tonnen',
	'A512RepairKitSlidePlates':'Reparatursatz Gleitplatten',
	'A512RPM':'Drehzahl pro Minute',
	'A513BagChairChange':'Sackstuhl-Wechsel',
	'A513BagTransBeltServo':'Rippenband Servo',
	'A514InfeedBelt':'Einlaufband',
	'A515FlattBelt':'Pressband',
	'A516BagTakingBelt':'Zuführband',
	'A517RollerConveyor':'Rollenbahn',
	'A518LiftingGantry':'Hubgerüst',
	'A519LinearDrive':'Linearantrieb',
	'A520SwivelJoint':'Drehgelenk',
	'A521LiftingGantry':'Rollenbahn Hubwerk',
	'A522FullPallets':'Rollenbahn Vollpalette',
	'A523EvacConveyor':'Staurollenbahn',
	'A524SafetyBarrier':'Lichtschranken / Sensoren',
	'A525BasicCleaning':'Grundreinigung',
	'A526SafetyDevices':'Sicherheitseinrichtungen',
	'A530CylSucBar':'Zylinder Saugerleisten schließen',
	'A531FilledBagGripper1':'Zylinder Vollsackgreifer 1',
	'A532FilledBagGripper2':'Zylinder Vollsackgreifer 2',
	'A533CylCutKnife':'Zylinder Schneidwerkzeug',
	'A534CylBotWelding':'Zylinder Bodennahtschweißung',
	'A535SpoutLift':'Stutzenanhebung',
	'A536CylClampTopWeld':'Zylinder Klemmleiste Kopfnaht',
	'A537CylTopWeld':'Zylinder Kopfnahtschweißung',
	'A538CylCornerWeld':'Zylinder Eckenschweißung',
	'A539AdjHeightDischargebelt':'Höhenverstellung Austrageband',
	'A540EmptyBagTransport':'Leersacktransport',
	'A541Virbator':'Betriebsstunden Rüttler',
	'A542CylCoolPlatesCornerWeld':'Zylinder Kühlplatten Eckenschweißung',
	'A543CylBagGuide':'Zylinder Sackleitblech',
	'A544CylClampBotWeld':'Zylinder Klemmleiste Bodennaht',
	'A545CylCoolBotWeld':'Zylinder Kontaktkühlung Bodennaht',
	'A546CylFilledBagGripper3':'Zylinder Vollsackgreifer 3',
	'A547CylStatBagGripper':'Zylinder Stationärer Greifer',
	'A548CylEmptyBagGripper1':'Zylinder Leersackgreifer 1',
	'A549CylStretching':'Zylinder Vorspreizung',
	'A550CylOpenSpout':'Zylinder Füllstutzen öffnen',
	'A551CylBagAplicatorLift':'Zylinder Sackanleger heben',
	'A552CylStretcherOpen':'Zylinder Spreizzangen öffnen',
	'A553CylStretcherInwards':'Zylinder Spreizzangen einwärts',
	'A554FoilTrackingDevice':'Folienabzug',
	'A555DischargeBelt2':'Betriebsstunden Austrageband BP2',
	'A556CylStretching':'Zylinder Nachspreizung',
	'A557CylEmptyBagGripper2':'Zylinder Leersackgreifer 2',
	'A558UnwindingDevice':'Betriebsstunden Abrollwalzen',
	'A559BagLenghtAdj':'Sacklängenausgleich',
	'A560CylVibratorLift':'Rüttleranhebung',
	'A561CylFilmGuide':'Zylinder Folienführung',
	'A562CylPushFlaps':'Zylinder Schubklappen',
	'A563CylWeldReelChanger':'Schweißung Rollenwechsler',
	'A564CylWeldReelChangerTef':'Teflon Schweißung Rollenwechsler',
	'A565DriveReelChanger':'Verfahrantrieb Rollenwechsler',
	'A566DriveAdjCornerWeld':'Antrieb Breitenverstellung Eckenschweißung',
	'A567CylThreadingInwards':'Zylinder Einfädelung Sauger zusammen',
	'A568CylThreadingOutwards':'Zylinder Einfädelung Begrenzung Sauger auseinander',
	'A569CylThreadingDownwards':'Zylinder Einfädelung Trennblech senken',
	'A570CylSucRailClose':'Zylinder Saugerleisten schließen',
	'A571DischargeBelt2':'Austrageband BP2',
	'A572CylSpoutOpen':'Zylinder Füllstutzen öffnen',
	'A573Drives':'Antriebe',
	'A574Cylinders':'Zylinder',
	'A575LowAttrition':'Niedriger Verschleiß',
	'A576HighAttrition':'Hoher Verschleiß',
	'A577SetUpTimeAvBatch':'Rüstzeit Ø',
	'A578SetUpTimeAv':'Rüstzeit Ø heute',
	'A579GrossTimeBatch':'Bruttozeit',
	'A580GrossTime':'Bruttozeit heute',
	'A581SetUpTime':'Rüstzeit',
	'A582Downtime':'Stillstand',
	'A583MonthBestPerf':'Monats-Bestleistung',
	'A584PressureChamperSeal':'Druckkammerdichtung',
	'A585Aeration':'Belüftungen',
	'A586SpillageRejector':'Rückmehlabweiser',
	'A587VibrationBottle':'Rüttelflasche',
	'A588VibrationTable':'Rütteltisch',
	'A589BagPlacing':'Sackaufsteckung',
	'A590RotaryFeeder':'Zellenradschleuse',
	'A591TopSeamCleaning':'Kopfnahtreinigung',
	'A592Beltplant1':'Bandanlage 1',
	'A593Beltplant2':'Bandanlage 2',
	'A594HydraulikPump':'Hydraulikpumpe',
	'A595DriveEBTS':'Motor Leersackübergabe',
	'A596HostingEBTS':'Hubwerk Leersackübergabe',
	'A597Ventilator':'Ventilator',
	'A598RD':'Rotrierender Teil',
	'A599SpoutCoarse':'Füllstutzen Grobstrom',
	'A600RotaryGateCoarse':'Drehschieber Grobstrom',
	'A601HostingVIC':'Hubwerk Vorverdichtung',
	'A602SpoutFine':'Füllstutzen Feinstrom',
	'A603RotaryGateFine':'Drehschieber Feinstrom',
	'A604HostingVSC1':'Hubwerk 2 Nachverdichtung',
	'A605HostingVSC2':'Hubwerk 3 Nachverdichtung',
	'A606FBTransfer':'Vollsacktransfer',
	'A607FBTHost':'Vollsacktransfer heben',
	'A608FBTswivel':'Vollsacktransfer schwenken',
	'A609FBTClamp':'Klemme Vollsacktransfer',
	'A610FBTransport':'Vollsacktransport',
	'A611FBTransportClamp':'Klemme Vollsacktransport',
	'A612HostBFS':'Hubwerk Sackformstation ',
	'A613SwivelBFS':'Sackformstation schwenken',
	'A614SideGussetblade':'Seitenfaltblech',
	'A615HostTopwelding':'Hubwerk Kopfnahtschweißung',
	'A616CloseTopWelding':'Kopfnahtschweißung schließen',
	'A617Folding1':'Umfaltung 1',
	'A618Folding2':'Umfaltung 2',
	'A619Folding3 ':'Umfaltung 3',
	'A620PUD':'Handlochstanze',
	'A621PUDWeld':'Schweißung Ausstanzung',
	'A622BeltReject':'Bandanlage Ausschleuser',
	'A623RejectingDevice':'Ausschleuser',
	'A624BagLenght':'Sacklänge',
	'A624PerfToday':'Leistung heute',
	'A625PerfTodayBatch':'Leistung',
	'A626Output':'Ausbringung heute',
	'A627OutputBatch':'Ausbringung',
	'A628UnitperH':'S/h',
	'A629Percent':'%',
	'A630BagJunction':'Sackweiche',
	'A631ToothedBelt':'Sacktr.riemen spannen',
	'A632LevellingFoot':'Gelenkfuss RAD prüfen',
	'A633PolymerGuide':'Polymerführung + Rolle',
	'A634BallBushing':'Kugelbuchse Koppel',
	'A635ScissorHoseReplace':'Scherenschlauch ausw.',
	'A636ConnectingHoseReplace':'Verbindungsschlauch ausw.',
	'A637Bellow':'Faltenbalg Höhenverst.',
	'A638TipperFrame':'Kipprahmen prüfen',
	'A639HosePressureChamber':'Schlauch Dr.kammer prüfen',
	'A640FlapFillingBox':'Klappe Fülltopf prüfen',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Stunden',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s ',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'Stk.',
	'A652WeldingTemperature':'Schweißtemperatur',
	'A653WeldingDuration':'Schweißdauer',
	'A654FillingSystem':'Füllsystem',
	'A655EmailSignature':'EMail Signatur',
	'A656NumberWasteBags':'Anzahl der Müllsacke',
	'A657FreqConverter':'Frequenzumrichter',
	'A658ServoDrives':'Servoantriebe',
	'A656Voltage':'Spannung',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'Strom',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'Frequenz',
	'A670Hertz':'Hz',
	'A671Pressure':'Druck',
	'A672Bar':'bar',
	'A673Moisture':'Drucktaupunkt',
	'A674AirFlowUnit':'l/s',
	'A675AnnualConsum':'Jährlicher Verbrauch',
	'A676MonthlyConsum':'Monatlicher Verbrauch',
	'A677FreeConsum':'Verbrauch',
	'A678PowerEfficiency':'Leistungseffizienz',
	'A679BagsPerKWH':'Säcke pro kW/h',
	'A681SinceReset':'Seit letztem Zurücksetzen',
	'A682LastTimeSortUse':'Seit letzter Nutzung',
	'A683SortNumber':'Sortennummer',
	'A684Overall':'Gesamt',
	'A685AirEfficiency':'Drucklufteffizienz',
	'A686BagsPerAirFlowUnit':'Säcke pro l/s',
	'A687Jan':'Januar',
	'A688Feb':'Februar',
	'A689Mar':'März',
	'A690Apr':'April',
	'A691Mai':'Mai',
	'A692Jun':'Juni',
	'A693Jul':'Juli',
	'A694Aug':'August',
	'A695Sep':'September',
	'A696Oct':'Oktober',
	'A697Nov':'November',
	'A698Dec':'Dezember',
	'A699Year':'Jahr',
	'A700EnterDateRange':'Datumsbereich eingeben',
	'A701PowerConsumption':'Stromverbrauch',
	'A702AirConsumption':'Luftverbrauch',
	'A703FlowControlGate':'Dosierschieber',
	'A704BagDirectionConv':'Sackrichtband',
	'A705BottomBearing':'Untere Lagerung',
	'A706PressureSwitch':'Druckschalter Sack-OK',
	'A707ElecModules':'Bauteile Elektronik',
	'A708SpoutWeight':'Bauteile Waage',
	'A709Search':'Suche',
	'A710Overweight':'Ausgeschl. Übergewicht',
	'A711Underweight':'Ausgeschl. Untergewicht',
	'A712RotaryDriveFreqConLoad':'Freq.-Umrichter Drehantrieb',
	'A713MeanValueLoadInPercent':'Mittelwert / Auslastung in Prozent',
	'A714EmptyBagDischarge':'Leersackausschleusung',
	'A715Bellow1':'Faltenbalg',
	'A716TotalCounter':'Zähler Total',
	'A717Spout0Counter':'Anzahl Stutzen 0',
	'A718LSdirtyCounter':'Lichtschranke KW verschmutzt',
	'A719Peripherie':'Peripherie',
	'A720Robot':'Roboter',
	'A721DepositBelt':'Ablageband',
	'A722RollerConveyor1':'Rollenband 1',
	'A723PalletCentering1':'Palettenzentrierung 1',
	'A724RollerConveyor2':'Rollenband 2',
	'A725PalletCentering2':'Palettenzentrierung 2',
	'A726GripperClamp':'Klemmung Greifer',
	'A727GripperMotor':'Motor Greifer',
	'A728AdjustmentCamera':'Verstellung Kamera',
	'A729BagSize':'Sackmaße',
	'A730PalletSupply':'Palettenzufuhr',
	'A731Length':'Länge',
	'A732Width':'Breite',
	'A733DepalPallets':'Depalettierte Paletten',
	'A734DepalBundles':'Depalettierte Bündel',
	'A735RejectedBundles':'Ausgeschleuste Bündel',
	'A736BagsPerBundle':'Säcke pro Bündel',
	'A737CountsOfDataPoints':'Anzahl der Datenpunkte',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Mittelwert Tendenz',
	'A740TrendSD':'Standardabweichung Tendenz',
	'A741PistonBlower1':'Drehkolbengebläse 1',
	'A742PistonBlower2':'Drehkolbengebläse 2',
	'A743RotaryFlap':'Drehklappe',
	'A744CurrentLayer':'Aktuelle Lage',
	'A745CurrentBag':'Aktueller Sack',
	'A746Days':'Tage',
	'A747LayerNo':'Lagennummer',
	'A748BagNo':'Sacknummer',
	'A749AnnualMaint':'Jahreswartung',
	'A901MachineCustomerOverview':'Maschine / Kundenübersicht',
	'A902Machines':'Maschinen',
	'A903Permissions':'Berechtigungen',
	'A904CompanyName':'Firmennamen',
	'A905MachineName':'Maschinennamen',
	'A906MachineType':'Maschinentyp',
	'A907Order':'Reihenfolge ',
	'A908Action':'Aktion',
	'A909Remove':'Entfernen',
	'A910ChooseCustomer':'Wählen Sie den Kunden',
	'A911ConnectMachines':'Maschinen verbinden',
	'A912User':'Benutzer',
	'A913FirstName':'Vorname',
	'A914Surname':'Nachname',
	'A915Login':'Login',
	'A916Roles':'Rollen',
	'A917Company':'Firma',
	'A918CreateUser':'Benutzer anlegen',
	'A919Username':'Benutzername',
	'A920Password':'Passwort',
	'A921Name':'Name',
	'A922ParentCompany':'Muttergesellschaft',
	'A923Create':'Erstellen',
	'A924Edit':'Bearbeiten',
	'A925SubCompany':'Subunternehmen',
	'A926Companies':'Firmen',
	'A927EditMachine':'Maschine bearbeiten',
	'A928CompaniesFunctionality':'Unternehmen /Funktionalität',
	'A930SelectAll':'Alles auswählen',
	'A931DetailView':'Detailansicht',
	'A932EditUser':'Benutzer bearbeiten',
	'A933Delete':'Löschen',
	'A934Cancel':'Abbrechen',
	'A935Save':'Speichern',
	'A936LoginFailed':'Anmeldung fehlgeschlagen. Ungültige Anmeldeinformationen.',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'Anzeigename 1',
	'A938DisplayName2':'Anzeigename 2',
	'A939DisplayName3':'Anzeigename 3',
	'A940ForgotPassword':'Passwort vergessen',
	'A941ForgotText':'Geben Sie Ihren Benutzernamen ein und Sie bekommen eine E-Mail mit einem Link, mit dem Sie Ihr Passwort zurücksetzen können',
	'A942Submit':'Senden',
	'A943EmailAddress':'E-Mail-Addresse',
	'A944ChangePassword':'Passwort ändern',
	'A945CurrentPassword':'Altes Passwort',
	'A946NewPassword':'Neues Passwort',
	'A947ConfirmPassword':'Neues Passwort wiederholen',
	'A948Update':'Speichern',
	'A949IncorrectPassword':'Falsches Passwort',
	'A950Metrics':'Metriken',
	'A950PasswordsNotMatch':'Neue Passwörter stimmen nicht überein.',
	'A951EmailNotFound':'E-Mail-Adresse nicht gefunden.',
	'A952InvalidEmail':'Geben Sie eine gültige E-Mail Adresse ein.',
	'A953EmailSent':'Wenn Ihre E-Mail in unserer Datenbank vorhanden ist, wird eine E-Mail mit dem Link zum Zurücksetzen an Ihre E-Mail-ID gesendet.',
	'A954ForgetPasswordMailSubject':'Setzen Sie bitte Ihr Passwort zurück.',
	'A955ForgetPasswordMailTitle':'Hallo USER_NAME,',
	'A956ForgetPasswordMailBody':'<p>Wir haben eine Anfrage bekommen, Ihr Passwort zurückzusetzen.<br>Verwenden Sie den untenstehenden Link, um ein neues Passwort für Ihren Account zu setzen. Wenn Sie das Zurücksetzten Ihres Passworts nicht angefordert haben, ignorieren Sie diese E-Mail und der Link läuft nach 24 Stunden automatisch ab.<p> <a href=\'RESET_LINK\' style=\'text-decoration: none; font-color: white;\'>Passwort zurücksetzen',
	'A957DateFormat':'dd.MM.yyyy',
	'A958D3DateFormat':'%d.%m.%Y',
	'A959DashboardMachineStateStandBy':'Bereithalten',
	'A959MessageUpdateSuccess':'Nachricht erfolgreich aktualisiert.',
	'A960MessageSaveSuccess':'Nachricht wurde erfolgreich gespeichert.',
	'A961Yes':'Ja',
	'A962No':'Nein',
	'A963ReportOverview':'Berichtsübersicht',
	'A964Machine':'Machine',
	'A965Card':'Karte',
	'A966ReportType':'Berichtstyp',
	'A967HistogramReport':'Histogrammbericht',
	'A968Report':'Bericht',
	'A969Day':'1 Tag',
	'A969French':'Français',
	'A970Week':'1 Woche',
	'A971Month':'1 Monat',
	'A972HistoryChart':'Verlaufsdiagramm',
	'A972HistoryChartBacth':'Verlaufsdiagramm akt. Batch',
	'A973Unit':'[Einheit]',
	'A974Time':'Zeit',
	'A975ShowBagCounterStackChart':'Stapeldiagramm',
	'A976TestReportStarted':'Testberichtgenerierung gestartet',
	'A977ActiveSettingsRequired':'Zum Erstellen eines Testberichts muss die Funktion in den Reporting-Einstellugen aktiviert sein',
	'A978FirstDayRequired':'Datum des ersten Berichts erforderlich.',
	'A979CompanyDeleted':'Firma wurde gelöscht.',
	'A980CompanyDeleteError':'Firma konnte nicht gelöscht werden:',
	'A981CompanyParentShouldDifferent':'Unternehmen und Muttergesellschaft müssen unterschiedlich sein.',
	'A982CompanyCreated':'Firma wurde gespeichert.',
	'A983CompanyCreateError':'Firma konnte nicht gespeichert werden:',
	'A984CompanyAllFields':'Geben Sie alle Felder ein',
	'A985UserCreated':'User wurde angelegt.',
	'A986UserExists':'E-Mail existiert bereits.',
	'A987UserDeleted':'Benutzer wurde gelöscht.',
	'A988IncludedInReport':'im Bericht enthalten',
	'A989IncludedInHistogram':'als Histogramm im Bericht enthalten',
	'A990DateFormat':'DD.MM.YYYY',
	'A991MachineTime':'Maschinenzeit',
	'A992LocalTime':'Lokale Zeit',
	'A993NoMachines':'Keine Maschinen zum Anzeigen',
	'A994Imprint':'Impressum',
	'A995PrivacyPolicy':'Datenschutzerklärung',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'Firmenname existiert bereits.',
	'B100ActivatingReports':'Das Aktivieren wird regelmäßige Berichte an folgende E-Mail-Adressen senden:',
	'B101EnterValidEmailIds':'Geben Sie eine gültige E-Mail-Adressen ein.',
	'B101ValidFrom':'Gültig ab',
	'B102AtleastOneEmailIdRequired':'Mindestens eine E-Mail-Adresse erforderlich.',
	'B102ValidTo':'Gültig bis',
	'B103MaximumLimitReached':'Höchstgrenze erreicht.',
	'C001FetchingWait':'Daten werden abgerufen. Bitte warten.',
	'C002PreparingWait':'Daten werden vorbereitet. Bitte warten.',
	'C003RenderingWait':'Diagramm wird erstellt. Bitte warten.',
	'C004PixelScale':'1 pixel ≈ {{timeInterval}} seconds.',
	'C005ChartError':'Fehler aufgetreten!',
	'C006ChartOk':'OK',
	'D001PortalType':'Portal',
	'D002CompanyDomain':'Look and Feel',
	'E001AlertSettingSavedSuccessfully':'Alarmmeldungen wurden erfolgreich gespeichert.',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Wählen Sie mindestens einen Alarmtyp aus, um die Alarmierung zu aktivieren.',
	'E003ErrorNumberMustBePositive':'Die Fehlernummer muss positiv sein.',
	'E004AlertEmailNeedsToBeActive':'Die Benachrichtigungs-E-Mail muss aktiv sein.',
	'E005AnErrorHasOccured':'Ein Fehler ist aufgetreten.',
	'E005ErrorMessageAlreadyExists':'Nachricht existiert bereits.',
	'E005OperationSuccesful':'Operation erfolgreich.',
	'E006ErrorMessageSave':'Fehler aufgetreten. Nachricht nicht gespeichert.',
	'E006ErrorOccurredSettingsWasNotSaved':'Ein Fehler ist aufgetreten. Einstellungen wurden nicht gespeichert.',
	'E007ReportSettingsSuccessfullySaved':'Berichtseinstellungen wurden erfolgreich gespeichert.',
	'E008ErrorSettingsDoNotExists':'Fehlereinstellungen existieren nicht.',
	'E009ReportSettingsSuccessfullyUpdated':'Berichtseinstellungen wurden erfolgreich aktualisiert.',
	'E010InvalidPassword':'Ungültiges Passwort',
	'E011UserUpdated':'Benutzer Aktualisiert',
	'E012InvalidPassword':'Das Passwort erfüllt nicht die aufgeführten Passwortkriterien.',
	'E013UserUpdated':'Der Benutzer wurde erfolgreich aktualisiert.',
	'E015CompanyMaxUsersLowerThanActiveUsers':'Das Unternehmen hat bereits mehr aktive Benutzer als die maximal festgelegten Benutzer.',
	'E016PasswordChangedSuccessfully':'Passwort wurde erfolgreich geändert.',
	'F001_CurrentOrder':'Aktueller Auftrag',
	'F002_ProductID':'Produkt ID',
	'F003_BatchStart':'Startzeit Batch',
	'F004_Targetweight':'Sollgewicht',
	'F005_RemainingFill':'Verbleibende Abfüllungen',
	'F006_CurrentProduction':'Aktuelle Produktion',
	'F007_FillStatistic':'Füllstatistik ',
	'F010_Sum':'Gesamt',
	'F011_Centering':'Zentrieren',
	'F012_Debunging':'Aufschrauben',
	'F013_Inert':'Inertisieren',
	'F014_Filling_1':'Füllen 1',
	'F015_Filling_2':'Füllen 2',
	'F016_Filling_3':'Füllen 3',
	'F017_Filling_4':'Füllen 4',
	'F018_Bunging':'Zuschrauben',
	'F019_Sealing':'Clinchen',
	'F020_Position':'Positionierung',
	'F021_TransportCycle':'Transporttakt',
	'F022_CheckWeigher':'Kontrollwaage',
	'F023_Closing':'Verschließung',
	'F024_Palletizing':'Palettierung',
	'F025_EmptyPaletMagazin':'Leerpalettenmagazin',
	'F026_DePalletizing':'Depalettierung',
	'F027_Transport':'Transport',
	'F028_PailSeperator':'Eimerentstapler',
	'F029_HangUpCap':'Deckelaufleger',
	'F030_PushInCap':'Deckeleindrücker',
	'F031_TestConsoles':'Prüfstation',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'Unter Toleranz',
	'F053_InTol':'In Toleranz',
	'F054_UpperTol':'Über Toleranz',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'Produktzuführung',
	'F061_EmptyTruss':'Leergebindezuführung',
	'F062DetailedDateFormat':'DD.MM.YYYY HH:mm:ss',
	'F090_Minuten':'Minuten',
	'F091_Stunden':'Stunden',
	'F100_Eichung':'nächste Eichung',
	'F101_TransKette':'Transportkette',
	'F102_Hubtor':'Hubtore',
	'F103_ZentRollen':'Zentrierrollen',
	'F104_SpundSuch':'Spundlochsucher',
	'F105_Aushub':'Aushub',
	'F106_WerkSchlitten':'Werkzeugschlitten',
	'F107_Aufschrauber':'Laufzeit Aufschrauber',
	'F108_VakuSys':'Vakuumsystem Aufschrauber',
	'F109_HubAufSchraub':'Zylinder Aufschrauber',
	'F110_OelAufSchraub':'Schmierungen Aufschrauber',
	'F111_Inertgas':'Inertisierung',
	'F112_Aushub_F1':'Aushub',
	'F113_HE_F1':'Hubeinheit',
	'F114_TFE_F1':'Tropfenfangschale',
	'F115_Aushub_F2':'Aushub',
	'F116_HE_F2':'Hubeinheit',
	'F117_TFE_F2':'Tropfenfangschale',
	'F118_ZentDorn':'Zentrierdorn',
	'F119_Schiebetisch':'Schiebetisch',
	'F120_Kappenband':'Stopfenband',
	'F121_VakuKap':'Vakuumsystem Stopfenvereinzelung',
	'F122_KapSort':'Schwingförderer',
	'F123_VerKap':'Stopfenvereinzelung',
	'F124_KapZu':'Stopfenzuführung',
	'F125_Schrauber':'Schrauber',
	'F126_VakuSchrauber':'Vakuumsystem Schrauber',
	'F127_HubSchrauber':'Zylinder Zuschrauber',
	'F128_OelZuSchraub':'Schmierungen Zuschrauber',
	'F129_Dichtung':'Dichtung Schrauberglocke',
	'F130_HubClinch':'Zylinder Clincher',
	'F131_Clinchen':'Clincher',
	'F132_OelClincher':'Schmierungen Clincher',
	'F133_VakuClincher':'Vakuumsystem Clincher',
	'G001BatchReport':'Chargenberichterstattung',
	'G002ActivateBatchReport':'Aktivierung der Batch-Berichte',
	'Hülsen MIN-Melder':'Hülse MIN-Melder',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'Kabel Sattelhöhenverstellung',
	'MACHINE_AVAILABILITY_RED_ZONE':'Maschinenverfügbarkeit in der roten Zone',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Maschinenverfügbarkeit in der gelben Zone',
	'Maint Interval 0':'Maint Interval 0',
	'Maint Interval 1':'Maint Interval 1',
	'Maint Interval 10':'Maint Interval 10',
	'Maint Interval 11':'Maint Interval 11',
	'Maint Interval 12':'Maint Interval 12',
	'Maint Interval 13':'Maint Interval 13',
	'Maint Interval 14':'Maint Interval 14',
	'Maint Interval 2':'Maint Interval 2',
	'Maint Interval 3':'Maint Interval 3',
	'Maint Interval 4':'Maint Interval 4',
	'Maint Interval 5':'Maint Interval 5',
	'Maint Interval 6':'Maint Interval 6',
	'Maint Interval 7':'Maint Interval 7',
	'Maint Interval 8':'Maint Interval 8',
	'Maint Interval 9':'Maint Interval 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'Globale Beschleunigung überschritten L0',
	'N042BrazAlertGlobalAccL1':'Globale Beschleunigung überschritten L1',
	'N042BrazAlertGlobalAccL2':'Globale Beschleunigung überschritten L2',
	'N042BrazAlertGlobalAccL3':'Globale Beschleunigung überschritten L3',
	'N042BrazAlertGlobalAccL4':'Globale Beschleunigung überschritten L4',
	'N042BrazAlertGlobalAmplL0':'Globale Amplitude überschritten L0',
	'N042BrazAlertGlobalAmplL1':'Globale Amplitude überschritten L1',
	'N042BrazAlertGlobalAmplL2':'Globale Amplitude überschritten L2',
	'N042BrazAlertGlobalAmplL3':'Globale Amplitude überschritten L3',
	'N042BrazAlertGlobalAmplL4':'Globale Amplitude überschritten L4',
	'N042BrazAlertMaxRmsAccL0':'Maximale Effektivbeschleunigung der LB-, CB- und RB-Sensoren überschritten L0',
	'N042BrazAlertMaxRmsAccL1':'Maximale Effektivbeschleunigung der LB-, CB- und RB-Sensoren überschritten L1',
	'N042BrazAlertMaxRmsAccL2':'Maximale Effektivbeschleunigung der LB-, CB- und RB-Sensoren überschritten L2',
	'N042BrazAlertMaxTempBearingSensL0':'Maximale Temperatur der Lagersensoren überschritten L0',
	'N042BrazAlertMaxTempBearingSensL1':'Maximale Temperatur der Lagersensoren überschritten L1',
	'N042BrazAlertMaxTempBearingSensL2':'Maximale Temperatur der Lagersensoren überschritten L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Maximale Beschleunigung der z-Achse von lfs, rfs lds und roten Sensoren überschritten L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Maximale Beschleunigung der z-Achse von lfs, rfs lds und roten Sensoren überschritten L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Maximale Beschleunigung der z-Achse von lfs, rfs lds und roten Sensoren überschritten L2',
	'N042BrazAlertRotFreqL0':'Drehzahl überschritten L0',
	'N042BrazAlertRotFreqL1':'Drehzahl überschritten L1',
	'N042BrazAlertRotFreqL2':'Drehzahl überschritten L2',
	'N042BrazAlertRotFreqL3':'Drehzahl überschritten L3',
	'N042BrazAlertRotFreqL4':'Drehzahl überschritten L4',
	'N042BrazAlertType':'Brasilianischer Alarm',
	'N042GlobalAcceleration':'Globale Beschleunigung',
	'N042GlobalAmplitude':'Globale Amplitude',
	'N042GlobalFrequency':'Globale Dehzahl',
	'N043CardCtxMenuShowConfigScreen':'Karte konfigurieren',
	'N043ConfigSaveFailure':'Konfigurationsaktualisierung fehlgeschlagen',
	'N043ConfigSaveSuccess':'Konfiguration erfolgreich aktualisiert',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Benachrichtigen Sie mich, wenn diese Grenze überschritten wird.',
	'N046RawDataTab':'Rohdaten',
	'N047SensorL1':'Sensor L 1',
	'N047SensorL4':'Sensor L 4',
	'N047SensorR1':'Sensor R 1',
	'N047SensorR4':'Sensor R 4',
	'N048Acceleration':'Beschleunigung',
	'N048AccelerationLocal':'Lokale Beschleunigung',
	'N048AccelerationX':'Beschleunigung X',
	'N048AccelerationY':'Beschleunigung Y',
	'N048AccelerationZ':'Beschleunigung Z',
	'N048Amplitude':'Amplitude',
	'N048AmplitudeLocal':'Amplitude lokal',
	'N048AmplitudeX':'Amplitude X',
	'N048AmplitudeY':'Amplitude Y',
	'N048AmplitudeZ':'Amplitude Z',
	'N048ChooseEndDate':'Wählen Sie ein Enddatum',
	'N048ChooseStartDate':'Wählen Sie ein Startdatum',
	'N048Constant':'Konstante',
	'N048CourseAngle':'Kurswinkel',
	'N048DeltaTemperature':'Delta-Temperatur',
	'N048Frequency':'Frequenz',
	'N048LastSeen':'Zuletzt gesehen',
	'N048MovementAngle':'Bewegungswinkel',
	'N048OffsetX':'X Offset',
	'N048OffsetY':'Y Offset',
	'N048OffsetZ':'Z Offset',
	'N048PhaseAngle':'Phasenwinkel',
	'N048MotionAngle':'Bewegungswinkel',
	'N048RMS':'RMS',
	'N048Rpm':'RPM',
	'N048SensorDescription':'Beschreibung Sensor',
	'N048SensorId':'Sensor ID',
	'N048SensorTemperature':'Sensortemperatur',
	'N048Side':'Ort',
	'N048SiteName':'Name vom Ort',
	'N048UpdatedTime':'Aktualisierte Zeit',
	'N049accelFDCompLeft':'Beschl.AAVergleich.Links',
	'N049accelFDCompRight':'Beschl.AAVergleich.Rechts',
	'N049accelLRCompDisch':'Beschl.LRVergleich.Abgabe',
	'N049accelLRCompFeed':'Beschl.LRVergleich.Aufgabe',
	'N049Date':'Datum',
	'N049ErrorFetchingData':'Fehler beim Abrufen der Daten',
	'N049FrequencyDaily':'Täglich',
	'N049FrequencyHourly':'Stündlich',
	'N049FrequencyWeekly':'Wöchentlich',
	'N049Length':'Länge',
	'N049ModelName':'Modell-Name',
	'N049pitch':'kippeln',
	'N049roll':'Rollen',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'Bewegungsabweichung',
	'N049TuningWizard':'Optimierungsassistent',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Breite',
	'N049xgFDCompLeft':'X.G.AAVergleich.Links',
	'N049xgFDCompRight':'X.G.AAVergleich.Rechts',
	'N049xgLrCompDisch':'X.G.LRVergleich.Auslauf',
	'N049xgLrCompFeed':'X.G.LRVergleich.Einlauf',
	'N049ygFDCompLeft':'Y.G.AAVergleich.Links',
	'N049ygFDCompRight':'Y.G.AAVergleich.Rechts',
	'N049ygLrCompDisch':'Y.G.LRVergleich.Auslauf',
	'N049ygLrCompFeed':'Y.G.LRVergleich.Einlauf',
	'N049zgFDCompLeft':'Z.G.AAVergleich.Links',
	'N049zgFDCompRight':'Z.G.AAVergleich.Rechts',
	'N049zgLrCompDisch':'Z.G.LRVergleich.Auslauf',
	'N049zgLrCompFeed':'Z.G.LRVergleich.Einlauf',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Analystenkommentar',
	'N050mAvgLong':'mavg lang',
	'N050mAvgShort':'mavg kurz',
	'N050TrendAnalysis':'Trend Analysen',
	'N051SensorL1':'Sensor L1',
	'N051SensorL2':'Sensor L2',
	'N051SensorL3':'Sensor L3',
	'N051SensorL4':'Sensor L4',
	'N051SensorMovement':'Sensorbewegung',
	'N051SensorR1':'Sensor R1',
	'N051SensorR2':'Sensor R2',
	'N051SensorR3':'Sensor R3',
	'N051SensorR4':'Sensor R4',
	'N052Anomaly':'Anomalie',
	'N052AnomalyDetectionTab':'Anomalieerkennung',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Sensor L1 lokale Amplitude L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Sensor L1 lokale Amplitude L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Sensor L1 lokale Amplitude L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Sensor L1 lokale Amplitude L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Sensor L1 lokale Amplitude L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Sensor L1 lokale Amplitude L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Sensor L1 Sensor Temperatur überschritten L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Sensor L1 Sensor Temperatur überschritten L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Sensor L1 Sensor Temperatur überschritten L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Sensor L1 Sensor Temperatur überschritten L3',
	'N052ObservedValue':'Beobachteten',
	'N053Angle':'Winkel',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Lokale Beschleunigung des Sensors L1 überschritten L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Lokale Beschleunigung des Sensors L1 überschritten L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Lokale Beschleunigung des Sensors L1 überschritten L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Lokale Beschleunigung des Sensors L1 überschritten L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Lokale Beschleunigung des Sensors L1 überschritten L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Lokale Beschleunigung des Sensors L1 überschritten L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Sensor L1 lokale Rotationsfrequenz L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'Sensor L1 lokale Rotationsfrequenz L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Sensor L1 lokale Rotationsfrequenz L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'Sensor L1 lokale Rotationsfrequenz L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'Sensor L1 lokale Rotationsfrequenz L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'Sensor L1 lokale Rotationsfrequenz L5',
	'N053BrazAlertTypeSL1ZAxisL0':'Beschleunigung der Z-Achse des Sensors L1 überschritten L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Beschleunigung der Z-Achse des Sensors L1 überschritten L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Beschleunigung der Z-Achse des Sensors L1 überschritten L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Beschleunigung der Z-Achse des Sensors L1 überschritten L3',
	'N053Displacement':'Auslenkung',
	'N053G':'g',
	'N053LocalAmplitude':'Lokale Amplitude',
	'N053LocalRotationFrequency':'Lokale Drehzahl',
	'N053MM':'mm',
	'N053XAxis':'X-Achse',
	'N053YAxis':'Y-Achse',
	'N053ZAxis':'Z-Achse',
	'N053ZAxisAcceleration':'Beschleunigung der Z-Achse',
	'N054Higher':'Höher',
	'N054Lower':'Niedriger',
	'N054StructuralAnalysis':'Strukturanalyse',
	'N054TWvarsForecasts':'Time wave Form Prognose',
	'N054TWvarsFull':'Time wave Form voll',
	'N054Value':'Wert',
	'N0551On0Off':'1 = EIN: 0 = AUS',
	'N055BrazTabGeneral':'Allgemeines',
	'N055ChooseStartEndDate':'Wählen Sie Startdatum und Enddatum',
	'N055Close':'Schließen',
	'N055Custom':'Benutzerdefiniert',
	'N055MaterialFlow':'Materialfluss',
	'N055MAxBearingTemperature':'Maximale Lagertemperatur',
	'N055MaxRmsAccelBearingSensors':'Max. Effektivbeschleunigung der Lagersensoren',
	'N055MazAccelZAxis':'Maximale Beschleunigung der Z-Achse von Struktursensoren',
	'N055Off':'AUS',
	'N055On':'AN',
	'N055RDCenter':'Maschinenansicht',
	'N055ShowHistory':'Historie anzeigen',
	'N055StartDateCannotBeAfterEndDate':'Das Startdatum darf nicht nach dem Enddatum liegen.',
	'N056Commentary':'Kommentar',
	'N056EvaluateEquipment':'Zu bewertende Bedingungen an der Ausrüstung',
	'N056High80':'Hoch 80',
	'N056High95':'Hoch 95',
	'N056Importance':'Bedeutung',
	'N056Low80':'Niedrig 80',
	'N056Low95':'Niedrig 95',
	'N056MaxLatAccelZgMax':'Maximale Querbeschleunigung ZG.Max',
	'N056Pred':'Vorhersage',
	'N056Recommendations':'Empfehlungen',
	'N056Type':'Art',
	'N056Report':'Bericht',
	'N0571Year':'1 Jahr',
	'N057AccelGms':'Beschleunigung (g)',
	'N057AccelRms':'Beschleunigung RMS',
	'N057AccelSpectrum':'Beschleunigungsspektrum',
	'N057AccelTimeWForm':'Beschleunigungszeitwellenform',
	'N057Axial':'Axial',
	'N057CrestFactor':'Scheitelfaktor',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Frequenz (Hz)',
	'N057FullSpectrum':'Gesamtes Spektrum',
	'N057FundBearingDefectFreq':'Lager Fehlerfrequenz',
	'N057HighHarBearFreq':'Höhere harmonische Lagerfrequenz',
	'N057HighRes':'Hohe Auflösung',
	'N057HistoricTemperature':'Historische Temperatur',
	'N057Horizontal':'Horizontal',
	'N057Latest':'Neueste',
	'N057LowHarBearFreq':'Niedrige harmonische Lagerfrequenz',
	'N057mms':'mm/s',
	'N057PeakToPeak':'Spitzenwert zu Spitzenwert',
	'N057PeakToPeakMg':'Spitzenwert zu Spitzenwert (mg)',
	'N057PowerBands':'Power Bands',
	'N057RedAlert':'Roter Alarm',
	'N057Rmsmg':'RMS (mg)',
	'N057Rmsmms':'RMS (mm/s)',
	'N057SensorLB1':'Sensor LB1',
	'N057SensorLB2':'Sensor LB2',
	'N057SensorRB1':'Sensor RB1',
	'N057SensorRB2':'Sensor RB2',
	'N057SensorCB1':'Sensor CB1',
	'N057SensorCB2':'Sensor CB2',
	'N057Spectrum':'Spektrum',
	'N057Temperature':'Temperatur',
	'N057TimeMs':'Zeit (ms)',
	'N057Trending':'Trend',
	'N057VelocityRms':'Geschwindigkeit RMS',
	'N057Vertical':'Vertikal',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'Gelber Alarm',
	'N058ImageUploadedFailure':'Bild hochgeladen Fehler',
	'N058ImageUploadedSuccesfully':'Bild erfolgreich hochgeladen',
	'N058UploadImage':'Bild hochladen',
	'N059Print':'Druck',
	'N060MachineHistoricalData':'Historische Daten Maschine',
	'N061HistoricalBearingData':'Historische Daten Lagerung',
	'N062HistoricalSpectrumData':'Historische Daten Spektrum',
	'N063GlobalAcceleration':'Globale Beschleunigung',
	'N064RotationFrequency':'Rotation (Frequenz)',
	'N065GlobalBreadth':'Globale Breite',
	'N066LocalAccelerationsEachStructureSensor':'Lokale Beschleunigungen der einzelnen Struktursensoren.',
	'N067MotionAngleEachStructureSensor':'Bewegungswinkel jedes Struktursensors',
	'N067PhaseAngleEachStructureSensor':'Phasenwinkel jedes Struktursensors',
	'N067LocalAmplitudesEachStructureSensor':'Lokale Amplituden der einzelnen Struktursensoren',
	'N068LateralAccelerationsStructureSensor':'Seitliche Beschleunigungen (Z-Achse) der einzelnen Struktursensoren',
	'N069TemperatureEachBearingSensor':'Temperatur der einzelnen Lagersensoren',
	'N070PeakToPeakEachBearingSensor':'Spitzenwert zu Spitzenwert jedes Lagersensors',
	'N071AccelerationRMS':'Beschleunigung RMS',
	'N072VelocityRMS':'Geschwindigkeit RMS',
	'N073CrestFactor':'Scheitelfaktor',
	'N074PowerBandsSensorLB1':'PowerBands - Sensor LB1',
	'N075PowerBandsSensorRB1':'PowerBands - Sensor RB1',
	'N076PowerBandsSensorLB2':'PowerBands - Sensor LB2',
	'N077PowerBandsSensorRB2':'PowerBands - Sensor RB2',
	'N077PowerBandsSensorCB1':'PowerBands - Sensor CB1',
	'N077PowerBandsSensorCB2':'PowerBands - Sensor CB2',
	'N078TimeWaveformDataAcceleration':'Timewaveform - Beschleunigung',
	'N079FrequencyDomainAcceleration':'Frequenzbereich - Beschleunigung',
	'N080Select':'Wählen Sie',
	'N081SelectedPeriod':'Ausgewählter Zeitraum',
	'N100Capacity':'Kapazität',
	'N101Product':'Produkt',
	'N102Undersized':'Unterdimensioniert',
	'N103Oversized':'Überdimensioniert',
	'N104Speed':'Geschwindigkeit',
	'N105Inclination':'Neigung',
	'N106BoardHeigh':'Höhe der Platte',
	'N107Moisture':'Luftfeuchtigkeit',
	'N108WaterAdd':'Wasserzusatz ',
	'N109OpMode':'Betriebsart',
	'N110Auto':'Automatikbetrieb',
	'N110Hand':'Handbetrieb',
	'N111OpHours':'Betriebsstunden',
	'N112Temp':'Temperatur',
	'N113BearingTemp1':'Lagertemperatur 1',
	'N114BearingTemp2':'Lagertemperatur 2',
	'N115DriveTemp':'Antriebstemperatur',
	'N116ConvTemp':'Wandlertemperatur',
	'N117PowerInput':'Leistungsaufnahme',
	'New001BagTransArea':'Bereich Sacktransport',
	'New002EmptyPalArea':'Bereich Leerpaletten',
	'New003FullPalArea':'Bereich Vollpaletten',
	'New004SheetDispArea':'Bereich Folienspeicher',
	'New005PneuFalut':'Pneumatische Störung',
	'New006ElectricFault':'Elektrische Störung',
	'New007Introduction':'Einführung',
	'New008Orientation':'Orientierung ',
	'New009PreGrouping':'Vorgruppierung',
	'New010Grouping':'Gruppierung',
	'New011RowPusher':'Reihenschieber',
	'New012SlideTable':'Schiebetisch & Andrücker',
	'New013Elevator':'Hubwerk',
	'New014Gantry':'Gestell',
	'New015Gripper':'Greifer',
	'New016FilmDispenser':'Filmabroller',
	'New017FullPalTrans':'Vollpaletten Transport',
	'New018EmpPalDisp':'Leerpaletten Magazin',
	'New019FilmDisp':'Filmabroller',
	'New020EmpCardDisp':'Karton Aufleger',
	'New021EmpPalTrans':'Leerpaletten Transport',
	'New022EjectingDev':'Sackausschleusung',
	'New023MetDetector':'Metalldetektor',
	'New024MarkingDev':'Beschriftungsgerät',
	'New025LabellingDev':'Etikettiergerät',
	'New026PalletStracker':'Palettenstapler',
	'New027PalletPress':'Palettenpresse',
	'New028Wrapper':'Wickler / Stretcher',
	'New029Fault':'Fehler',
	'New030StandBy':'In Bereitschaft',
	'New031BagProduction':'Sack Produktion',
	'New032WeightDetection':'Gewichtserkennung',
	'New033LeakingDetection':'Leckage-Erkennung',
	'New034PrintingFault':'Fehler Drucker',
	'New035BagProduced':'produzierte Säcke',
	'New036SamplingEjection':'Ausschleusung Probenahme',
	'New037PalletProduction':'Paletten Produktion',
	'New038PalletInfeed':'Palettenzufuhr',
	'New039PalletOutfeed':'Palettenauslauf',
	'New040PalletIncomplete':'Unvollständige Palette',
	'New041Checkweigher':'Kontrollwaage',
	'New041PalledProduced':'Produzierte Paletten',
	'New042ProgramChngsToday':'Programmwechsel heute',
	'New042ProgramChngs':'Programmwechsel',
	'New043EndofBatch':'Ende der Batch',
	'New044ProductonTime':'Produktionszeit',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'Waagenabgleich',
	'Zyklen':'Zyklen',
	'N082AlertMe':'Informieren Sie mich, wenn diese Karte Benachrichtigungen enthält.',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'Synchronisiert',
	'N091NotSynced':'Nicht synchronisiert',
	'N084New':'Neu',
	'N085ConfigSettingSavedSuccessfully':'Konfigurationseinstellungen erfolgreich gespeichert.',
	'N86ErrorOccurredSettingsWasNotSaved':'Fehler beim Speichern der Konfigurationseinstellungen.',
	'A496OEEWeek':'Jede zweite Woche',
	'A497OEEMonthly':'Monatlich',
	'N87ServiceRequestUploadFiles':'Dateien hochladen',
	'N88ServiceRequestFileSizeError':'Datei ist zu groß',
	'N89ServiceRequestFileTypeError':'Dateitypen werden nicht unterstützt',
	'N90FilesUploadedSuccesfully':'Erfolgreich Datei hochgeladen',
	'N91FilesUploadedFailure':'Fehler beim Hochladen von Dateien',
	'N932IncludeExcelReport':'Excel-Berichte einbeziehen',
	'N94ServiceRequestFileLimitError':'Datei über Limit',
	'N055MaxHAxisRmsAccelBearingSensors':'Max. horiz. RMS Beschleunigung',
	'N055MaxAxialRmsAccelBearingSensors':'Max. axiale RMS Beschleunigung',
	'N053MG':'mg',
	'N056DummyMachine':'Dummy Maschine',
	'N057NiagaraAlertYellow':'Gelber Alarm',
	'N058NiagaraAlertOrange':'Orangener Alarm',
	'N059NiagaraAlertRed':'Roter Alarm',
	'N060AddDummyMachine':'Dummy Maschine hinzufügen',
	'N96ConfigureValue':'Konfigurationswert',
	'N97NoDataAvailable':'Keine Daten verfügbar',
	'N98TimeStamp':'Zeitstempel',
	'N99Documentation':'Dokumentation',
	'N100ErrorFileHeader':'Fehlermeldungen',
	'N101DocumentationHeader':'Dokumentationskopf',
	'N102DownloadMachineTypeErrorMessageFile':'Standard Maschinentyp',
	'N102DownloadMachineErrorMessageFile':'Maschinenfehler Datei',
	'N103ErrorInRetrievingErrorMessages':'Fehler beim Abrufen von Fehlermeldungen.',
	'E011MaintIntervalSubmitSuccess':'Wartungsintervall erfolgreich gespeichert.',
	'A368OpenPartsShop':'Öffnen Ersatzteilkatalog',
	'N104ExtSparePartCatalog':'HAVER Ersatzteilkatalog',
	'N105OpenExtSparePartCatalog':'Öffnen HAVER Ersatzteilkatalog',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Grad)',
	'N108UseRelativeValues':'Relative Werte verwenden',
	'N109PartsShopUsername':'Benutzername Ersatzteilkatalog',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'Zustand Vibrationskörper',
	'AX_BearingCondition':'Zustand Lagerung',
	'AX_AlarmsCount':'Anzahl der Alarme',
	'AX_Last24Hours':'In den letzten 24 Stunden',
	'D001TransBearingCondition':'Gut',
	'D002TransBearingCondition':'Gut | Abnehmend',
	'D003TransBearingCondition':'Gut | Stabil',
	'D004TransBearingCondition':'Gut | Zunehmend',
	'D005TransBearingCondition':'Akzeptable',
	'D006TransBearingCondition':'Akzeptable | Abnehmend',
	'D007TransBearingCondition':'Akzeptable | Stabil',
	'D008TransBearingCondition':'Akzeptable | Zunehmend',
	'D009TransBearingCondition':'Ernsthaft',
	'D010TransBearingCondition':'Ernsthaft | Abnehmend',
	'D011TransBearingCondition':'Ernsthaft | Stabil',
	'D012TransBearingCondition':'Ernsthaft | Zunehmend',
	'D013TransBearingCondition':'Kritisch',
	'D014TransBearingCondition':'Kritisch | Abnehmend',
	'D015TransBearingCondition':'Kritisch | Stabil',
	'D016TransBearingCondition':'Kritisch | Zunehmend',
	'D001TransVBCondition':'Gut',
	'D002TransVBCondition':'Gut | Abnehmend',
	'D003TransVBCondition':'Gut | Stabil',
	'D004TransVBCondition':'Gut | Zunehmend',
	'D005TransVBCondition':'Akzeptable',
	'D006TransVBCondition':'Akzeptable | Abnehmend',
	'D007TransVBCondition':'Akzeptable | Stabil',
	'D008TransVBCondition':'Akzeptable | Zunehmend',
	'D009TransVBCondition':'Ernsthaft',
	'D010TransVBCondition':'Ernsthaft | Abnehmend',
	'D011TransVBCondition':'Ernsthaft | Stabil',
	'D012TransVBCondition':'Ernsthaft | Zunehmend',
	'D013TransVBCondition':'Kritisch',
	'D014TransVBCondition':'Kritisch | Abnehmend',
	'D015TransVBCondition':'Kritisch | Stabil',
	'D016TransVBCondition':'Kritisch | Zunehmend',
	'F01CardExpProduction':'<p><strong>Produktionsleistung </strong>ist die aktuelle Leistung der Anlage, angegeben in<strong> Einheiten pro Stunde </strong>.<br>- Einheiten = St&uuml;ck</p><p>Der<strong> Farbumschlag </strong>ist wie folgt definiert:<br>- rot&nbsp; 0-70%<br>- gelb&nbsp; 70-90%<br>- gr&uuml;n&nbsp; 90-100%</p><p>Die<strong> maximale Leistung </strong>wird durch die <u>vertraglich verkaufte Leistung</u> der Packanlage oder durch die festgelegte<u> sortenspezifische Leistung</u> definiert.</p>',
	'F01CardExpAvailability':'<p><strong>Verf&uuml;gbarkeit </strong>ist die aktuelle Verf&uuml;gbarkeit der Anlage, angegeben in<strong> Prozent </strong>.</p><p>Die<strong> Verf&uuml;gbarkeit </strong>mit 100% ist wie folgt definiert:<br>- Steuerpannung eingeschaltet<br>- Filteranlage in Betrieb<br>- Druckluft vorhanden<br>- keine Gruppenst&ouml;rungen<br>- alle vorhanden F&uuml;llstationen in Betrieb<br>- RADIMAT<sup>&reg;</sup>vorhanden und eingeschaltet</p><p>Der<strong> Farbumschlag </strong>ist wie folgt definiert:<br>- rot&nbsp; 0-70%<br>- gelb&nbsp; 70-90%<br>- gr&uuml;n&nbsp; 90-100%</p>',
	'F01CardExpMaintenance':'<p><strong>Maschinenwartung </strong>ist der Wartungsz&auml;hler der gesamten Anlage, angegeben in<strong> Einheiten </strong>.<br>- Einheiten = St&uuml;ck</p><p>Der<strong> Schwellwert </strong>ist nicht direkt &uuml;ber das Portal ver&auml;nderbar.<br>Bei &Auml;nderungsw&uuml;nschen bitte die<strong> HAVER Hotline </strong>kontaktieren.<br>eMail:<a href=\"mailto:hotline@haverboecker.com\">hotline@haverboecker.com</a></p>',
	'F01CardExpProductionTime':'<p><strong>Gesamtzeit </strong>ist die Anzeige eines gesamten Produktionstages, angegeben in<strong> Minuten </strong>.<br>- 1.440 Minuten = 1 Tag</p><p>Die<strong> Legende </strong>gibt Auskunft &uuml;ber folgende Situationen:<br>- dunkelblau:&nbsp; reine<em> Produktionszeit  </em>der Anlage<br>- hellblau:&nbsp; nachfolgende<em> Bandanlage  </em>gest&ouml;rt<br>- grau:&nbsp; kein<em> Produkt  </em>vorhanden<br>- gelb:&nbsp; keine<em> Packmittel  </em>vorhanden (z.B. Leers&auml;cke, Eimer oder Paletten)<br>- gr&uuml;n:&nbsp;<em> R&uuml;stzeit  </em>, zum Beispiel ein Produkt-/Sortenwechsel<br>- rot:&nbsp;<em> Stillst&auml;nde  </em>, wenn keines der anderen Zust&auml;nde aktiv ist und Steuerspannung eingeschaltet ist</p>',
	'F01CardExpProductionTimeBaumit':'<p><strong>Unterbrechungen </strong>ist die Anzeige der Summe aller Unterbrechungen eines gesamten Produktionstages, angegeben in<strong> Minuten </strong>.</p><p>Die<strong> Legende </strong>gibt Auskunft &uuml;ber folgende Herkunft der Unterbrechungen:<br>- cyan:&nbsp;<em>kein Austrag </em>, d.h. nachfolgende Bandanlage gest&ouml;rt<br>- olive:&nbsp;<em>kein Produkt </em>vorhanden<br>- orange:&nbsp;<em>keine Packmittel </em>vorhanden (z.B. Leers&auml;cke, Eimer oder Paletten)<br>- violett:&nbsp;<em>St&ouml;rungen Maschine </em>, die Summe aller Gruppenst&ouml;rungen</p>',
	'F01CardExpProductionToday':'<p><strong>Produktion heute </strong>ist die aktuelle Tagesproduktion der Anlage, angegeben in<strong> Einheiten </strong>.<br>- Einheiten = St&uuml;ck</p>',
	'F01CardExpRemProduction':'<p><strong>Gesamtproduktion </strong>ist der remanente Gesamtz&auml;hlerstand der Anlage, angegeben in<strong> Einheiten </strong>.<br>- Einheiten = St&uuml;ck</p><p>Der Datenpunkt ist nicht<em> r&uuml;cksetzbar.  </em></p>',
	'F01CardExpLastReset':'<p><strong>Letzter Reset </strong>ist der letzte Zeitpunkt vom R&uuml;cksetzen allen Datenpunkte der Anlage<strong>. </strong><br>-<em>Zeitzone </em>der Anlage vor Ort</p><p>Der <strong>Zeitstempel </strong>wird durch die<em> koordinierte Weltzeit </em><strong> (UTC) </strong>der Anlage vor Ort angezeigt<strong>. </strong></p>',
	'F01CardExpFaults_C':'<p><strong>Unterbrechungen heute </strong>ist die Summe aller auftretenden Unterbrechungen der Anlage, angegeben in<strong> Anzahl </strong>.</p><p>Die genaue Auflistung der<strong> Unterbrechungen </strong>befindet sich in der maschinenbezogenen Seite (2. Tab).</p>',
	'F01CardExpFaults_M':'<p><strong>Unterbrechungen heute </strong>ist die Summe aller auftretenden Unterbrechungen der Anlage, angegeben in<strong> Minuten </strong>.</p><p>Die genaue Auflistung der<strong> Unterbrechungen </strong>in<strong> Minuten </strong>befindet sich in der maschinenbezogenen Seite (2. Tab).</p>',
	'F01CardExpEnergy':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFillingTime':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBagManufacture':'<p><strong>Gutproduktion </strong>ist der Qualit&auml;tsindikator f&uuml;r die <em>gutgewichtigen</em> Gebinde, angegeben in<strong> Prozent</strong>.</p><p>Der Wert steht im Verh&auml;ltnis zwischen <u>Produktion heute</u> und <u>Ausschleusungen</u>.</p><p>Der<strong> Farbumschlag </strong>ist wie folgt definiert:<br>- rot&nbsp; 0-70%<br>- gelb&nbsp; 70-90%<br>- gr&uuml;n&nbsp; 90-100%</p>',
	'F01CardExpWeightData':'<p><strong>Mittelgewicht</strong> ist das aktuell ermittelte Mittelgewicht pro F&uuml;llstation, angegeben in der vor Ort verwendeten <strong>Gewichtseinheit</strong> (kg oder lb).</p><p>Das Mittelgewicht bezieht sich immer auf die <u>aktuelle Sorte</u>. Nach einem Sortenwechsel werden die Gewichtsdaten zur&uuml;ckgesetzt.</p>',
	'F01CardExpStdDevData':'<p><strong>Standardabweichung</strong> ist die aktuell ermittelte Standardabweichung pro F&uuml;llstation, angegeben in der vor Ort verwendeten <strong>Gewichtseinheit</strong> (g oder lb).</p><p>Die Standardabweichung bezieht sich immer auf die <u>aktuelle Sorte</u>. Nach einem Sortenwechsel werden die Gewichtsdaten zur&uuml;ckgesetzt.</p>',
	'F01CardExpIncorrectWeight':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpInterrupts':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenden Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten </strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpTypeChange':'<p><strong>Sortenwechsel </strong>ist die Anzahl aller get&auml;tigten Sortenwechsel der Anlage, angegeben in<strong> Anzahl</strong>.</p><p>Mit der<em> Sorten&uuml;bernahme  </em>an der Anlage wird der<strong> Sortenwechsel </strong>eingeleitet.<br>Durch den Abwurf des<em> ersten  </em>Gebinde in der <u>neuen</u> Sorte wird der<strong> Sortenwechsel </strong>beendet und der Z&auml;hlerstand um 1 erh&ouml;ht.</p>',
	'F01CardExpBagCounterPerFS':'<p><strong>Tagessackz&auml;hler </strong>ist die Aufteilung der produzierten Gebinde pro F&uuml;llstation, angegeben in<strong> Einheiten </strong>und in<strong> Prozent</strong>.</p><p>- 1. Spalte: Einheiten = St&uuml;ck<br>- 2. Spalte: Prozent = prozentuale Aufteilung (&sum; = 100%)</p>',
	'F01CardExpNotPlacedBags':'<p><strong>Nicht aufgesteckte S&auml;cke </strong>ist die Aufteilung der fehl aufgesteckten Leergebinde pro F&uuml;llstation, angegeben in <strong>Einheiten </strong>und in<strong> Prozent.</strong></p><p>- 1. Spalte: Einheiten = St&uuml;ck<br>- 2. Spalte: Prozent = prozentuale Aufteilung zu produzierte Gebinde</p>',
	'F01CardExpEmptyBagMagazineToday':'<p><strong>Neubef&uuml;llung Magazin </strong>ist die Anzahl aller get&auml;tigten Neubef&uuml;llungen des Sackmagazins der Anlage, angegeben in<strong>Anzahl </strong>.</p>',
	'F01CardExpRejectsToday':'<p><strong>Ausschleusungen </strong>ist die Anzahl aller ausgeschleusten unter-/ &uuml;bergewichtigen Gebinde der Anlage, angegeben in<strong> Anzahl </strong>.</p><p>Das Signal f&uuml;r die<strong> Ausschleusungen </strong>kommt, wenn vorhanden, direkt aus der Bandkontrollwaage.<br>Es k&ouml;nnen sowohl<em> unter-/  </em>als auch<em> &uuml;bergewichtige  </em>Gebinde ermittelt werden.</p>',
	'F01CardExpCollectiveFaultsToday':'<p><strong>Sammelst&ouml;rungen </strong>ist die Aufteilung aller aufgetretenden St&ouml;rungen am<em> Aufstecker  </em>und an den installierten<em> F&uuml;llstationen </em>, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.<br>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller St&ouml;rungen)</p>',
	'F01CardExpGoodWeights':'<p><strong>Gutproduktion </strong>ist der Qualit&auml;tsindikator f&uuml;r die <em>gutgewichtigen</em> Gebinde, angegeben in<strong> Prozent</strong>.</p><p>Der Wert steht im Verh&auml;ltnis zwischen <u>Produktion heute</u> und <u>Ausschleusungen</u>.</p><p>Der<strong> Farbumschlag </strong>ist wie folgt definiert:<br>- rot&nbsp; 0-70%<br>- gelb&nbsp; 70-90%<br>- gr&uuml;n&nbsp; 90-100%</p>',
	'F01CardExpFltCompAir':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpStrokesPump':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMaxStrokeDur':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpAvgStrokes':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpInternalPressure':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFillings':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpStartOfOrder':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpProductID':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpTargetWeight':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFillingCount':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpRemainingNumberFillings':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpCycleTime':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFaultLength':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpExtFaultLength':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpWithinTolerance':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOutsideTolerance':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpCurrentProduction':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFillStatistic':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpRDCenter':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpGlobalAcceleration':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpGlobalFrequency':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpGlobalAmplitude':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMazAccelZAxis':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMaxRmsAccelBearingSensors':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMAxBearingTemperature':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpSensorMovement':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpAccelerationLocal':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpAcceleration':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpAxisAcceleration':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpLocalRotationFrequency':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpLocalAmplitude':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpDisplacement':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpPhaseAngle':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMotionAngle':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpSensorTemperature':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBagQuality':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFaults':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBunChangeAbs':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBunChangeToday':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBagsPerBundle':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpTypeChanges':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpSortChangeToday':'<p><strong>Sortenwechsel heute </strong>ist die Anzahl aller get&auml;tigten Sortenwechsel der Anlage, angegeben in<strong> Anzahl</strong>.</p><p>Mit der<em> Sorten&uuml;bernahme </em>an der Anlage wird der<strong> Sortenwechsel </strong>eingeleitet.<br>Mit dem Abwurf des<em> ersten </em>Gebinde in der <u>neuen</u> Sorte wird der<strong> Sortenwechsel </strong>beendet und der Z&auml;hlerstand um 1 erh&ouml;ht.</p>',
	'F01CardExpCyclesToday':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMetalDetectorToday':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpIncorrectBags':'<p><strong>Ausgeschleuste fehlgewichtige Säcke </strong>ist die Anzahl aller registrierten fehlgewichtigen Gebinde der Anlage, angegeben in<strong>Anzahl </strong>.</p>',
	'F01CardExpOpenBags':'<p><strong>Ausgeschleuste offene Säcke </strong>ist die Anzahl aller registrierten offen und nicht korrekt verschlossenen Gebinde der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpSampleBags':'<p><strong>Ausgeschleuste Probesäcke </strong>ist die Anzahl aller erzeugten Probesäcke an der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpRejectionsToday':'<p><strong>Ausschleusungen </strong>ist die Anzahl aller ausgeschleusten unter-/ &uuml;bergewichtigen Gebinde der Anlage, angegeben in<strong> Anzahl </strong>.</p><p>Das Signal f&uuml;r die<strong> Ausschleusungen </strong>kommt, wenn vorhanden, direkt aus der Bandkontrollwaage.<br>Es k&ouml;nnen sowohl<em> unter-/  </em>als auch<em> &uuml;bergewichtige  </em>Gebinde ermittelt werden.</p>',
	'F01CardExpContainer':'<p><strong>Container </strong>ist die Aufteilung der produzierten Gebinde pro Container, angegeben in<strong> Einheiten</strong> und in<strong> Prozent</strong>.</p><p>- 1. Spalte: Einheiten = St&uuml;ck<br>- 2. Spalte: Prozent = prozentuale Aufteilung (&sum; = 100%)</p>',
	'F01CardExpEmptyRejects':'<p><strong>Ausschuss leere S&auml;cke heute </strong>ist die Anzahl aller fehl aufgesteckten Leergebinde der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpFullRejects':'<p><strong>Ausschuss volle S&auml;cke heute </strong>ist die Anzahl aller fehlgewichtigen oder offenen Gebinde der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpProductionRate':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpDailyProduction':'<p><strong>Produktion heute </strong>ist die aktuelle Tagesproduktion der Anlage, angegeben in<strong> Kilogramm</strong>.</p>',
	'F01CardExpFillingStation':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpPalletizer':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpEmptyPalArea':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBagTransArea':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFullPalArea':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpSheetDispArea':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpProductonTime':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpProgramChngsToday':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpEndofBatch':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpEmptyPallets':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBagProduction':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpPalletProduction':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFSDifference':'<p><strong>Streuung Sackz&auml;hler </strong>ist der Qualit&auml;tsunterschied zwischen der <u>besten</u> F&uuml;llstation und der <u>schlechtesten</u> F&uuml;llstation, angegeben in<strong> Prozent</strong>.</p>',
	'F01CardExpProduct':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpCapacity':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpUndersized':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOversized':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpSpeed':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpInclination':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpBoardHeigh':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpMoisture':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpWaterAdd':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOpMode':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOpHours':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpTemp':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpPowerInput':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpFullPalletCount':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpLoadEmptyPallets':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOeeValue':'<p><strong>OEE Kennzahl </strong>ist die aktuelle Kennzahl der <strong>Gesamtanlageneffektivität</strong>, angegeben in<strong> Prozent</strong>.</p><p>&nbsp;</p><p>Die <strong>Gesamtanlageneffektivität </strong>besteht aus den Faktoren <i>Verfügbarkeit</i>, <i>Leistungsgrad</i>und <i>Qualitätsrate</i>.</p>',
	'F01CardExpOeeAvailable':'<p><strong>Verfügbarkeitsfaktor </strong>ist die aktuelle Kennzahl der <strong>Verfügbarkeit</strong>, angegeben in<strong> Prozent</strong>.</p><p>&nbsp;</p><p>Der <strong>Verfügbarkeitsfaktor</strong> ist das Verhältnis aus der Zeit bis zum Auftreten eines Fehlers und der Zeit des Ausfalls der Funktion.</p>',
	'F01CardExpOeePerformance':'<p><strong>Leistungsfaktor </strong>ist die aktuelle Kennzahl der <strong>Leistung</strong>, angegeben in<strong> Prozent</strong>.</p><p>&nbsp;</p><p>Der <strong>Leistungsfaktor</strong> ist ein Maß für Verluste durch Abweichung von der geplanten Stückzeit, kleineren Ausfällen (Stillstände, die nicht in die <i>Verfügbarkeit </i>eingehen) und Leerläufen.</p>',
	'F01CardExpOeeQuality':'<p><strong>Qualitätsfaktor </strong>ist die aktuelle Kennzahl der <strong>Qualität</strong>, angegeben in<strong> Prozent</strong>.</p><p>&nbsp;</p><p>Der <strong>Qualitätsfaktor</strong> ist ein Maß für den Verlust aufgrund <i>defekter </i>und zu <i>überarbeitender Teile</i>.</p>',
	'F01CardExpOeeDowntime':'<p><strong>Stillstände </strong>ist die Anzeige der Summe der unterschiedlichen Stillstände der Anlage zur Berechnung des<i>Verfügbarkeitsfaktors </i>, angegeben in <strong>Minuten</strong>.</p><p>&nbsp;</p><p>Die <strong>Legende</strong> gibt Auskunft über folgende Arten von Stillständen:<br>- gelb: <i>weitere Stillstände</i><br>- grau: <i>Maschinen-Stillstände</i><br>- hellblau: <i>geplante Stillstände</i></p>',
	'F01CardExpOperatingFactor':'<p><strong>Betriebsfaktor </strong>ist die Kennzahl der reinen Betriebszeit der Anlage zur Berechnung des <i>Leistungsfaktors </i>, angegeben in <strong>Prozent</strong>.</p>',
	'F01CardExpThroughput':'<p><strong>Durchsatzfaktor </strong>ist die Kennzahl der reinen Laufzeit der Anlage zur Berechnung des <i>Leistungsfaktors </i>, angegeben in <strong>Prozent</strong>.</p>',
	'F01CardExpOeeRejects':'<p><strong>Ausschuss heute </strong>ist die Aufteilung der unterschiedlich zugeordneten ausgeschleusten Gebinde der Anlage zur Berechnung des <i>Qualitätsfaktors</i>, angegeben in<strong> Einheiten</strong>.<br>- Einheiten = Stück</p>',
	'F01CardExpMachineDetailPage':'<p>Mit der <strong>Umschalttaste</strong> l&auml;&szlig;t sich die Anzeige aller Daten zwischen <u>Tageswerte</u> und <u>Sortenwerte</u> umstellen.</p><p>Die gew&auml;hlte Darstellungsart wird zus&auml;tzlich mit dem <u>blauen</u>  <u>orangenen</u> Sym',
	'F01CardExpReportSettingsPage':'<p>Im Bereich <strong>Berichterstattung</strong> k&ouml;nnen Einstellungen f&uuml;r Berichte vorgenommen werden.</p><p>Mit dem Schiebeschalter <strong>aktiviert</strong> wird das Senden der Berichte ein-/ausgeschaltet.</p><p>Unter <strong>Frequenz</strong> kann der Zeitintervall f&uuml;r die Berichterstattung eingestellt werden. Mehrfachauswahl ist m&ouml;glich.</p><p>Unter <strong>Dynamische Inhalte</strong> k&ouml;nnen weitere Einstellungen vorgenommer werden<br>-<u>abgelaufene Wartungsintervalle:</u> jeder einzelne Wartungsintervall, bei dem der Schwellwert &uuml;ber 100% betr&auml;gt, wird im Bericht dargestellt<br>-<u>Aktivierung der Batch-Berichte:</u> beim Sorten-/Produktwechsel wird ein Zwischenbericht der alten Sorte/Produkt gesendet<br>-<u>Excel-Berichte einbeziehen:</u> Daten werden zus&auml;tzlich im Rohformat in einer *.csv Datei als Anhang gesendet</p><p>Auswahl der Sprache des gesendeten Berichts<br>Auswahl des Sende-Zeitpunkts der Berichterstattung<br>Auswahl des 1. Tags der Woche bei Zusendung von Wochen-/Monatsbericht</p><p>Nach get&auml;tigten &Auml;nderungen auf <strong>Speichern </strong>dr&uuml;cken!</p><p>&nbsp;</p>',
	'F01CardExpReportOverviewPage':'<p>Im Bereich <strong>Berichts&uuml;bersicht</strong> sind die ausgew&auml;hlten Datenpunkt f&uuml;r den Bericht aufgef&uuml;hrt.</p><p>&Uuml;ber das <strong>rote Kreuzsymbol</strong> lassen sich <u>einzelne</u> Datenpunkte entfernen.</p><p>&Uuml;ber das <strong>M&uuml;lleimer Symbol</strong> lassen sich <u>alle</u> Datenpunkte entfernen.</p><p>Mit dem <strong>\"Pfeil nach unten\" Symbol</strong> wird ein Testbericht generiert.</p><p>&nbsp;</p>',
	'F01CardExpCompanyOverviewPage':'<p>Im Register <strong>Dashboard </strong>werden alle verbundenen Maschinen der Gesamtanlage gezeigt.</p><p>N&auml;here Details zu den einzelnen Kacheln k&ouml;nnen &uuml;ber die jeweiligen Hilfe-Taste abgerufen werden.</p><p>F&uuml;r mehr Informationen&nbsp; links in der Liste die entsprechende Maschine ausw&auml;hlen.</p><p>Der<strong> Status </strong>ist wie folgt definiert:<br>- blau:&nbsp; in Betrieb<br>- rot:&nbsp; in St&ouml;rung<br>- grau:&nbsp; in Bereitschaft<br>- wei&szlig;:&nbsp; Wartung erforderlich<br>- schwarz:&nbsp; ausgeschaltet</p>',
	'F01CardExpAlertSettingsPage':'<p>Im Bereich <strong>Alarmmeldungen</strong> k&ouml;nnen Einstellungen f&uuml;r das Alerting vorgenommen werden.</p><p>Mit dem Schiebeschalter <strong>aktiviert</strong> wird die Benachrichtigung via Push-Nachrichten (links) und/oder via eMail (rechts) ein-/ausgeschaltet.</p><p>In der Checkbox können individuell die <strong>Ereignisse</strong> f&uuml;r die Benachrichtigungen selektiert werden.</p><p>Nach get&auml;tigten &Auml;nderungen auf <strong>Speichern </strong>dr&uuml;cken!</p>',
	'F01CardExpMsgAdminPage':'<p>Im Bereich <strong>Nachrichtenverwaltung</strong> können vordefinierte Fehlermeldungen und deren Eigenschaften für den SPS-Trigger deklariert werden.</p>',
	'F01CardExpUserRolesPage':'<p>Im Bereich <strong>Benutzer &amp; Rollen </strong>k&ouml;nnen neue User im TYP Portal angelegt werden.</p><p>Hierzu unten in der Fu&szlig;zeile das blaue',
	'F01CardExpCompaniesPage':'<p>Im Bereich <strong>Firmen </strong>k&ouml;nnen neue Firmen im TYP Portal angelegt werden.</p><p>Hierzu unten in der Fu&szlig;zeile das blaue',
	'F01CardExpDummyMachinePage':'<p>Im Bereich <strong>Dummy Maschine </strong>k&ouml;nnen nicht mehr genutzte bzw. gek&uuml;ndigte Maschinen im TYP Portal eingef&uuml;gt werden.</p><p>Hierzu unten in der Fu&szlig;zeile das blaue',
	'F01CardExpPermissionsPage':'<p>Im Bereich <strong>Berechtigungen </strong>k&ouml;nnen s&auml;mtliche aktuell verf&uuml;gbaren Funktionalit&auml;ten des TYP Portals f&uuml;r jede Firma individuell aktiviert/deaktiviert werden.</p><p>Hierzu die entsprechende Firma ausw&auml;hlen und anschlie&szlig;end die entsprechendne Einstellungen vornehmen.</p><p>Der Zugang zur Seitenleisten-Navigation <strong>Verwaltung / Berechtigungen </strong>ist nur f&uuml;r den ADMIN des TYP Portal sichtbar.</p>',
	'F01CardExpMaintenanceAidPage':'<p>Im Bereich <strong>Wartungshilfen </strong>k&ouml;nnen entsprechende Wartungshilfen f&uuml;r den Register <u>Maschinenwartung</u> hochgeladen werden.</p><p>Hierzu den entsprechenden Kunden sowie Maschine ausw&auml;hlen und die Dateien hochladen. Es werden Dokumente in WORD, PDF als auch Fotos und Videos unterst&uuml;tzt.</p><p>Der Zugang zur Seitenleisten-Navigation <strong>Verwaltung / Wartungshilfen </strong>ist nur f&uuml;r den ADMIN des TYP Portal sichtbar.</p>',
	'F01CardExpMachinePage':'<p>Im Bereich <strong>Maschinen </strong>k&ouml;nnen neue Maschinen im TYP Portal angelegt werden.</p><p>Hierzu unten in der Fu&szlig;zeile das blaue',
	'F01CardExpLogBookTab':'<p>Im Register <strong>Logbuch </strong>werden s&auml;mtliche Alarmmeldungen der Gesamtanlage gelistet.</p><p>Das Erstellen von Alarmmeldungen muss zun&auml;chst im Bereich <u>Alarmmeldungen</u> (auf der linken Seite) aktiviert werden.</p><p>Alle dort befindlichen <u>Alarmmeldungen</u> werden bei entsprechender Aktivierung im Logbuch gelistet.</p><p>Zus&auml;tzlich ist es m&ouml;glich, jederzeit manuell Logbucheintr&auml;ge zu erstellen. Hierbei wird zwischen <u>Info</u>, <u>Task</u> und <u>Alert</u> unterschieden.</p>',
	'F01CardExpServiceRequestTab':'<p>Im Register <strong>Serviceanfrage</strong> k&ouml;nnen diverse Anfragen rund um die Anlage oder dem TYP Portal get&auml;tigt werden.</p><p>Zus&auml;tzlich besteht die M&ouml;glichkeit, weitere Informationen, wie z.B. Dokumente, Fotos oder Videos hochzuladen.</p><p>Die <strong>Serviceanfrage</strong> wird direkt an die f&uuml;r den <u>Maschinentyp verantwortliche Hotline</u> weitergeleitet und zeitnah bearbeitet.</p>',
	'F01CardExpMessagesTab':'<p>Im Register <strong>Meldungen Maschine </strong>können alle registrierten Meldungen aus dem HMI angezeigt werden.</p><p>Die Meldungen sind in verschiedene Gruppen eingeteilt:</p><p>- Fehler-Meldungen (FM</p><p>- Operator-Meldungen (OM)</p><p>- Warte-Meldungen (WM)</p>',
	'F01CardExpDocumentSTab':'Hier finden Sie bald eine Erklärung für diese Kachel',
	'F01CardExpOeeTab':'<p>Im Register <strong>OEE </strong>wird die Kennzahl der Gesamtanlageneffektivität (oder englisch<i>Overall Equipment Effectiveness, OEE',
	'F01CardExpMaintenanceTab':'<p>Im Register <strong>Maschinenwartung </strong>k&ouml;nnen bis zu 200 Wartungsintervalle der Gesamtanlage gezeigt werden.</p><p>Die Wartungsintervalle sind in verschiedenen Gruppen unterteilt.</p><p>Mit der Funktion <u>Zur&uuml;cksetzen</u> k&ouml;nnen alle in der Gruppe befindlichen Wartungsintervalle zusammen auf 0 gesetzt werden.</p><p>Mit dem &Ouml;ffnen des Pull-down-Men&uuml; werden alle in der Gruppe befindlichen Wartungsintervalle dargestellt.</p><p>Die eingestellten Schwellwerte entsprechen den Vorgaben der Technischen Dokumentation oder sind Erfahrungswerte von H&B Servicetechnikern.</p><p>Der<strong> Farbumschlag </strong>ist wie folgt definiert:<br>- gr&uuml;n&nbsp; 0-70%<br>- gelb&nbsp; 70-90%<br>- rot&nbsp; 90-100%</p>',
	'F01CardExpMachineSpecificTab':'<p>Im <strong>maschinenbezogenen </strong> Register werden die speziell f&uuml;r den Maschinentyp bestimmten Informationen gezeigt.</p><p>N&auml;here Details zu den einzelnen Kacheln k&ouml;nnen &uuml;ber die jeweiligen Hilfe-Taste abgerufen werden.</p>',
	'F01CardExpGeneralTab':'<p>Im Register <strong>Allgemein </strong>werden allgemeine Informationen der Gesamtanlage gezeigt.</p><p>N&auml;here Details zu den einzelnen Kacheln k&ouml;nnen &uuml;ber die jeweiligen Hilfe-Taste abgerufen werden.</p><p>Der Register <strong>Allgemein</strong> ist nicht maschinenabh&auml;ngig. Es wird die allgemeing&uuml;ltige Bezeichnung <u>Einheiten</u> verwendet.</p>',
	'F01CardExpGoodPerformance':'<p><strong>Produktion heute </strong>ist die Aufteilung der produzierten Gebinde der gesamten Packanlage, angegeben in<strong> Einheiten</strong>.<br>- Einheiten = St&uuml;ck</p><p>- Gesamtproduktion:&nbsp; Summe aller abgeworfenen Gebinde<br>- Falsch aufgesteckte S&auml;cke:&nbsp; Summe aller fehlaufgesteckten Leers&auml;cke<br>- Ausschleusung:&nbsp; Summe aller ausgeschleusten Gebinde<br>- Fehlgewicht:&nbsp; Summe aller ausgeschleusten unter-/&uuml;bergewichtigen Gebinde<br>- Offene S&auml;cke:&nbsp; Summe aller nicht korrekt verschlossenen Gebinde</p>',
	'F01CardExpWrongApplBags':'<p><strong>Falsch aufgesteckte Säcke </strong>ist die Anzahl aller fehl aufgesteckten Leergebinde der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpReelChanges':'<p><strong>Rollenwechsel heute </strong>ist die Anzahl aller durchgeführten Rollenwechsel an der Anlage, angegeben in<strong> Anzahl</strong>.</p>',
	'F01CardExpTypeCurrent':'<p><strong>Aktuelle Sorte </strong>ist der aktuell produzierte Produkt-/ Sortenname der Anlage.</p><p>&nbsp;</p><p>Der Produkt-/ Sortenname kommt, wenn vorhanden, direkt aus der Bandkontrollwaage, ansonsten aus der MEC® Waagensteuerung.</p>',
	'F01CardExpInterruptsFeigeGeneral':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsAdams':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsISF':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsIVT':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsMiniAdams':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsRVT':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'F01CardExpInterruptsNewtecTerram':'<p><strong>Unterbrechungen </strong>ist die Aufteilung aller aufgetretenen Unterbrechungen in den verschiedenen Produktionsbereichen, angegeben in<strong> Anzahl </strong>und in<strong> Minuten</strong>.</p><p>- 1. Spalte: Anzahl<br>- 2. Spalte: Dauer in Minuten (Summe aller Unterbrechungen)</p>',
	'A-AlertTip-main_acc-ORANGE':'Hauptbeschleunigung orange Alarm',
	'A-AlertTip-main_acc-YELLOW':'Hauptbeschleunigung gelb Alarm',
	'A-AlertTip-main_acc-RED':'Hauptbeschleunigung rot Alarm',
	'A-AlertTip-u8MachAvail-AVAIL':'Verfügbarkeit Alarm',
};